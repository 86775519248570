//import axios,{post} from 'axios';
import {axiosClient,axiosClientforCreateProduct,config} from '../services/axiosClient'
class Daabia  /*extends Window */ {
  
  constructor(store_id){
    /*  super(Window) */
     window['daabia_storeid']=store_id;
     this.storeid=store_id
     console.log(" initiating module ... "+window.daabia_storeid)
  }

  

  async  fetchProducts(){
     //console.log(window.storeid)
    try{
       const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/products/store/${this.storeid}`);
       const data=await res.json();
            // console.log(data);
             return data.products;
    }catch(error){
       console.log(error)
    };

    
  }


 /*  var body={
      name:name,
      price:price,
      shippingFees:shippingFees,
      categoryId:categoryId,
      description:description,
      specification:specification,
      digitalProductUrl:digitalProductUrl,
      storeid:storeid,
      stock:storeid,
      active:active,
      productImages:productImages,
      productCategory:productCategory
    } */
  /* async  initiateAndCreateProduct(auth_token,colors,sizes,name,price,shippingFees,categoryId,description,specification,digitalProductUrl,storeid,stock,active,productImages,productCategory){

  
    
    const url = '${process.env.REACT_APP_SERVER_URL}/products/';

    //console.log(colors);
    const formData = new FormData();
    //getInput values
   // let colors = getInputValues('color-specs');
    //let sizes  = getInputValues('size-specs');

    for (let i = 0; i < colors.length; i++) { //crreates color array
       if(colors[i]!==""){
        formData.append('color', colors[i]);
       }
    }
    for (let j = 0; j < sizes.length; j++) {//creates soze array
      if (sizes[j]!==""){
        formData.append('size', sizes[j]);
      }
    }
    formData.append('name', name);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('specification', specification);
    formData.append('digital_product_url', digitalProductUrl);
    formData.append('storeId', storeid);
    formData.append('stock',stock);
    formData.append('active',active);
    formData.append('shippingFees',shippingFees);
    formData.append('category',JSON.stringify(productCategory));

    //append files to image to create an a file array
  
    for (var i = 0; i <= productImages.length; i++) {
      formData.append('image', productImages[i]);
      //console.log(productImages);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'auth-token':
          auth_token,
      },
    }
    return axios.post(url, formData, config)
  
  };
*/ 
} 


 class Product  {
  
     constructor(){
      this.storeid=window.daabia_storeid
       console.log("get with Product class "+window.daabia_storeid);
     
     }

    async get(){ //gets all products from store
      
      try{
         const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/products/store/${window.daabia_storeid}`);
         const data=await res.json();
               //console.log(data);
               return data.products;
      }catch(error){
      
      }
    }

    async getById(id){ //gets all products by storeId @id
      try{
         const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/products/${id}`);
         const data=await res.json();
               console.log(data);
               return data.products;
      }catch(error){
         console.lloog(error)
      }
    }
    async sales(id){ //gets all products by storeId @id
      try{
         const response = await axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/analytics/product/sales/${id}`,{productId:id,storeId:this.storeid},config);
       
               return response
      }catch(error){
        console.log(error)
      }
    }

    async salesMonthly(id){ //gets all products by storeId @id
      try{
         const response = await axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/analytics//transactions/product/sales/monthly/${id}`,{productId:id,storeId:this.storeid},config);
       
               return response
      }catch(error){
        console.log(error)
      }
    }
      async  edit (id,body){
      const url = `${process.env.REACT_APP_SERVER_URL}/products/${id}`;
    /*   const colval=getValues('color');
      console.log(colval);
      const sizeval=getValues('size'); */

      /* example body
      const body={
               productId:productid,
               price:price,
               shippingFees:shippingFees,
               description:description,
               stock:addStock,
               active:active,
               color:colors,
               size:sizes,
               
      } */
     /**/  const config = {
             headers:{
                 Authorization:`Bearer ${body.accessToken}`,
                 'Content-Type': 'application/json',

      }
      } 
      return await axiosClient.patch(url, body,config)
    
    };
    
    async  create(accessToken,body){ //creates new product
      /*  var body={
         name:name,
         price:price,
         shippingFees:shippingFees,
         categoryId:categoryId,
         description:description,
         specification:specification,
         digitalProductUrl:digitalProductUrl,
         storeid:storeid,
         stock:storeid,
         active:active,
         productImages:productImages,
         productCategory:productCategory
       } */
       
    
   
       //const formData = new FormData();
       //getInput values
      // let colors = getInputValues('color-specs');
       //let sizes  = getInputValues('size-specs');
      /*   var colors=body.colors
       for (let i = 0; i < colors.length; i++) {//crreates color array
          if(colors[i]!==""){
           formData.append('color', colors[i]);
          }
       }
       var sizes=body.sizes;

       for (let j = 0; j < sizes.length; j++) {//creates soze array
         if (sizes[j]!==""){
           formData.append('size', sizes[j]);
         }
       }
       
       let productCategory=body.productCategory

       formData.append('name', body.name);
       formData.append('price', body.price);
       formData.append('description', body.description);
       formData.append('specification', body.specification);
       formData.append('digital_product_url', body.digitalProductUrl);//append digital
       formData.append('storeId', body.storeid);
       formData.append('stock',body.stock);
       formData.append('active',body.active);
       formData.append('shippingFees',body.shippingFees);
       formData.append('category',JSON.stringify(productCategory));
       formData.append('countryScope',JSON.stringify(body.countryScope))
       formData.append('length',body.imageslength); */
       //formData.append('categoryGroupId',productCategory.categoryGroupId);


       //append files to image to create an a file array
      /*  for (var i = 0; i <= body.productImages.length; i++) {
         formData.append('image', body.productImages[i]);
         //console.log(productImages);
       }
    */   
      const url = `${process.env.REACT_APP_SERVER_URL}/products/`;
      const config = {
        headers:{
          Authorization:`Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          }
  
      }
       return await axiosClientforCreateProduct.post(url, JSON.stringify(body), config)
     
     }

    async  delete(_id) {
      try {
        const response =  await axiosClient.delete(`${process.env.REACT_APP_SERVER_URL}/products/${_id}`,config);
        console.log(response)
              return response;
      } catch (error) {
        console.error(error);
      }
    }
  }
  class Store{
    constructor(){
      this.storeid=window.daabia_storeid
      console.log(window.daabia_storeid)
    }
    async create(auth_token,body){
     console.log(body)
     /* var body={
        name :body.storeName,
        description : body.description,
        country: body.countrylabel,
        state : body.statelabel,
         city : body.citylabel,
         email :body.email,
         phone : body.phone,
         userId: body.userId,
         categories:body.checkedCategories,
         currency: body.currencylabel,
         encodedimages:body.encodedimages
      }
      var imagefiles=[]
      for (var i = 0; i <= files.length; i++) {
        imagefiles.push(files[i]);
      
       
      const formData = new FormData();
      formData.append('name', body.name);
      formData.append('description', body.description);
      formData.append('country', body.countrylabel);
      formData.append('state', body.statelabel);
      formData.append('city', body.citylabel);
      formData.append('email', body.email);
      formData.append('phone', body.phone);
      formData.append('userId', userId);
      formData.append('categories',JSON.stringify(body.checkedCategories))
      formData.append('currency', body.currencylabel);
      formData.append('status', "Unverified");
      formData.append('length',body.imageslength);
      formData.append('encodedimages',body.encodedimages)
}*/
     /*  var files=body.files;
      for (var i = 0; i <= files.length; i++) {
        formData.append('image', files[i]);
      }
  
      for (var s = 0; s <= checkedCategories.length; s++) {
        formData.append('categories', JSON.stringify(checkedCategories[i]));
      } */
  
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'auth-token': auth_token,
        }
  
      }
      return axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/stores/`, JSON.stringify(body), config)
    }
    async get(userId){ 
       try {
       if (userId){
        const response = await axiosClient.get(`${process.env.REACT_APP_SERVER_URL}/stores/user/${userId}`,config);
        //console.log(response);
        return response
       }
      } catch (error) {
        console.error(error);
      }
      
    }

    async status(storeId){ 
      try {
      if (storeId){
       const response = await axiosClient.get(`${process.env.REACT_APP_SERVER_URL}/stores/status/${storeId}`,config);
       console.log(response);
        if (response.status===200){
          return response
         }
      }
     } catch (error) {
       console.error(error);
     }
     
   }
   
    async getByStoreId(storeId){
    try {
        const response = await axiosClient.get(`${process.env.REACT_APP_SERVER_URL}/stores/${storeId}`,config);
        //console.log(response);
        return response
      } catch (error) {
        console.error(error);
      }
    }
    async edit(body){
          try{
     // console.log(window.daabia_storeid)
        
    const url = `${process.env.REACT_APP_SERVER_URL}/stores/${window.daabia_storeid}`;
   /*   const body={
             storeId:store._id,
             name:name,
             email:email,
             phone:phone,
             description:description,
             country:country,
             state:state,
             city:city,
             currency:currencyLabel,
             categories:checkedCategories

    } */
    const formData=new FormData();
          formData.append("storeId",window.daabia_storeid);
          formData.append("name",body.name);
          formData.append("email",body.email);
          formData.append("phone",body.phone);
          formData.append("description",body.description);
          formData.append("country",body.country);
          formData.append("state",body.state);
          formData.append("city",body.city);
          formData.append("image",body.image);
          formData.append("categories",JSON.stringify(body.checkedCategories));
          formData.append('length',body.imageslength);
          formData.append('encodedimages',body.encodedimages)
          formData.append('currency', body.currency);


  
    const config = {
        headers: {
            'Content-Type':'application/json',
            'auth-token':body.auth_token,
          },
         
          
    }

    return axiosClient.patch(url, body,config)
    }catch(err){
      console.log(err)
    }
    }
    async delete(_id){
      try {
        const response = await axiosClient.delete(`${process.env.REACT_APP_SERVER_URL}/stores/${_id}`,config);
        return response
      } catch (error) {
        console.error(error);
      }
    }
    
  }

  class Analytics {
    constructor(){
      this.storeid=window.daabia_storeid
      console.log(window.daabia_storei)
    }
     //get store sales
   async sales(){
  
    var url = `${process.env.REACT_APP_SERVER_URL}/analytics/sales/${this.storeid}`
  
     const response = await axiosClient.get(url,config);
    
    return response;
  }

    //get store sales
    async salesById(storeId){
      var url = `${process.env.REACT_APP_SERVER_URL}/analytics/sales/${storeId}`
    
     const response = await axiosClient.get(url,config);
      
      return response;
    }
   //get sales by each month
   async monthlySales(year){
    var url = `${process.env.REACT_APP_SERVER_URL}/analytics/sales/monthly/${this.storeid}`
  
   const response = await axiosClient.post(url,{year:year},config);
    
    return response;
  }

    //Get fulle report sales completed and incompleted 
    async report(){
    console.log(this.storeid)
    var url = `${process.env.REACT_APP_SERVER_URL}/analytics/report/${this.storeid}`

    return await axiosClient.get(url,config);
  }
}
  //initiate Classes
const product   = new Product();
const store     = new Store();
const analytics = new Analytics();
export  { Daabia,product,store,analytics};
