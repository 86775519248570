import React from "react";

const styles = {
  appbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "40px",
    background: "#f0f0f0",
    padding: "0 2px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  icon: {
    width: "24px",
    height: "24px",
    background: "#ddd",
   /*  borderRadius: "50%", */
  },
  searchInput: {
    flex: 1,
    height: "36px",
    background: "#ddd",
    borderRadius: "30px",
    padding: "0 12px",
    margin: "0 16px",
  },
  "@media (max-width: 768px)": {
    appbar: {
      padding: "0 12px",
    },
    icon: {
      width: "20px",
      height: "20px",
    },
    searchInput: {
      height: "32px",
      margin: "0 8px",
    },
  },
};

const TopBarFallback = () => {
  return (
    <div style={styles.appbar}>
      {/* Left Icon */}
      <div style={styles.icon}></div>

      {/* Search Input */}
      <div style={styles.searchInput}></div>

      {/* Right Icons */}
      <div style={styles.iconContainer}>
        <div style={styles.icon}></div>
        <div style={styles.icon}></div>
      </div>
    </div>
  );
};

export default TopBarFallback;
