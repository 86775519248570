import React,{useState} from 'react'
import {Grid} from '@mui/material'
import { ExpandMore,ExpandLess} from '@mui/icons-material';
import './index.css';
import useStyles from  './styles'
import NestedList from './nestedList/NestedList'

const Category= ({onHandlegetCatgeryProducts,handlesearchByCategoryGroups,handleonGroupExpandClick,handlesearchByCategory,groupCategories,categories,productCategories,deviceType}) => {
console.log(categories)
  
    const classes=useStyles()

  const CategoryWidget = ({item,onHandlegetCatgeryGroupProducts,handlesearchByCategory})=>{

    return(
      <div className="categoryItem" onClick={()=>{onHandlegetCatgeryGroupProducts(item._id)}}>
      <span className="categoryItemTitle">{item.name}</span>
       <div className="categoryItemContent">
           <span className="categoryProducts">{item.products}</span>
           <span className="categoryMoneyRate">-11.4
       </span>
       </div>
      </div>

  )
  }
  const CategoryWidgetMobile = ({item,onHandlegetCatgeryGroupProducts,handlesearchByCategory,onGroupExpandClick})=>{
          const[expandMore,setExpandMore]=useState(false)
    return(
    
      <Grid container justifyContent='space-between' className='category-widget-mobile' margin={0.5}  spacing={0}>
       <Grid item > 
       <span className="category-span-item" onClick={()=>{onHandlegetCatgeryGroupProducts(item._id)}}>{item.name}</span>
      {/*  <span className="category-span-item">{item.products}</span> */}
       </Grid>
       <Grid item onClick={()=>{onGroupExpandClick(item);setExpandMore(!expandMore)}}>
          <Grid item justifyContent={'center'} >
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item > 
              <span className="category-span-item-count">{/* {item.categoriesCount} */}
             </span></Grid>
             <Grid item>
             {expandMore ? <ExpandLess style={{fontSize:'16',border:'0px solid',marginBottom:'-5px'}}/> : <ExpandMore style={{fontSize:'16',border:'0px solid',marginBottom:'-5px'}}/>}
              </Grid>
              
            </Grid>
         
           </Grid>
       </Grid>
      </Grid>
  )
}
  return (
   <div className={classes.content}>
    <NestedList groupCategories={groupCategories} categories={productCategories} handlesearchByCategoryGroups={handlesearchByCategoryGroups} handlesearchByCategory={handlesearchByCategory}/>
    {/*  {deviceType ==='mobile' ? <div>
      
      <Grid container justifyContent="space-between" spacing={0} margin={0} padding={1}>
       {
         groupCategories.map((item,index)=>(
           <Grid item key={index} xs={'auto'}>
              <CategoryWidgetMobile index={index} item={item} onHandlegetCatgeryGroupProducts={handlesearchByCategoryGroups} onHandlegetCatgeryProducts={handlesearchByCategory} onGroupExpandClick={handleonGroupExpandClick} />
           </Grid>
         ))
       }
    </Grid> 
    
    <Grid container padding={2} direction={'column'}>
       {
        categories.length > 0 ? categories.map((item,index)=>(
           <Grid item key={index} xs={'auto'} onClick={()=>{handlesearchByCategory(item._id)}}>
                <Grid container >
                  <Grid item >
                    <span className='category-widget-mobile'>{item.name}</span></Grid>
                </Grid>
           </Grid>
         )):''
       }
    </Grid>
   
    </div>
    
    :<Grid container justifyContent="space-between" spacing={1} >
       {
         groupCategories.map((item,index)=>(
           <Grid item={true} key={index} xs={6} sm={4} md={4} lg={4}>
              <CategoryWidget index={index} item={item} onHandlegetCatgeryGroupProducts={handlesearchByCategoryGroups} onHandlegetCatgeryProducts={handlesearchByCategory}/>
           </Grid>
         ))
       }
    </Grid>} */}
    
   </div>
  )
}

export default Category
