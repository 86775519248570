/* eslint-disable no-unused-vars */
import React,{useState} from 'react'
import {ListItem} from '@mui/material';
import CustomTypography from '../customComponent/CustomTypography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useStyles from './styles';
import {formarttoPiCurrency,convertValueFromExponent,truncateString } from '../../utils/Utils';
import { Grid } from '@mui/material';

const Review = ({cart,t}) => {
     //var fees =convertValueFromExponent(cart.subfees)
    const[items]=useState(cart.items);
    const[refresh,setRefresh]=useState(false);
    const [open, setOpen] = React.useState(true);
    const classes= useStyles()
  const handleClick = () => {
    setOpen(!open);
  };
    

  const SummaryItems = ({items})=>{
     // Filter the list based on a condition
     const filteredItems = items.filter(item => item.selected === true);

    return(
      <div /* style={{height:'48vh',overflow:'scroll',padding:'10px'}} */ className={classes.rewiewwrapper}>

     
      <Grid container justifyContent={'space-between'} direction={'column'}  spacing={2}>
            {/* Map over the filtered list and render components */}
            {filteredItems.map((item, index) => (
                 <Grid item key={`summary-item-${index}`} >
                 <Grid container justifyContent={'space-between'} >
                  
                  <Grid item xs={10}>
                      <Grid container justifyContent={'space-between'} >
                           <Grid item >
                             <CustomTypography  fontWeight={500}>{truncateString(item.product.name,48)}</CustomTypography>
                           </Grid>
        
                           {/* <Grid item >
                             <CustomTypography  color='darkgrey'>{`${t("form.product.quantity.label")} ${item.quantity}  ${item.color!=='null'? ' , '+item.color :''} ${item.size!=='null' ? ' , '+item.size:''}`}</CustomTypography>
                           </Grid> */}
                      </Grid>
                  </Grid>
        
                  <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'} direction={'column'}>
                        

                        <Grid item >
                        <Grid container justifyContent={'space-between'}> 
                        <Grid item>
                     
                        <CustomTypography  color='textGrey'>{`${t("form.product.quantity.label")}`}</CustomTypography>
                        </Grid>
                          <Grid item >
                          <CustomTypography  color='textGrey'>{` ${item.quantity} `}</CustomTypography>
                          </Grid>
                        </Grid>
                        </Grid>

                        

                        { item.color !== 'null'? <Grid item >
                        <Grid container justifyContent={'space-between'}> 
                        <Grid item>
                     
                        <CustomTypography /* fontSize={{ xs: "0.5rem", sm: "0.7rem", lg: "1rem" }} */ color='textGrey'>{`${t("form.product.colorspecification.label")}`}</CustomTypography>
                        </Grid>
                          <Grid item >
                          <CustomTypography  color='textGrey'>{` ${item.color!=='null'? '  '+item.color :''} `}</CustomTypography>
                          </Grid>
                        </Grid>
                        </Grid>:''}
                        
                        { item.size !== 'null'? <Grid item >
                        <Grid container justifyContent={'space-between'}> 
                        <Grid item>
                     
                        <CustomTypography  color='textGrey'>{`${t("form.product.sizespecification.label")}`}</CustomTypography>
                        </Grid>
                          <Grid item >
                          <CustomTypography  color='textGrey'>{` ${item.size!=='null'? '  '+item.size :''} `}</CustomTypography>
                          </Grid>
                        </Grid>
                        </Grid>:''}
                        <Grid item >
                        <Grid container justifyContent={'space-between'}>
                          <Grid item >
                          <CustomTypography variant="body2" fontSize='0.8rem' color='textGrey'>{`${t("form.product.price.label")}`}</CustomTypography>
                          </Grid>
                          <Grid item >
                          <CustomTypography variant="body2" fontSize='0.8rem' color='textGrey'>{` ${formarttoPiCurrency(item.product.price)}`}</CustomTypography>
                            </Grid>
                        </Grid>
                   
                        </Grid>


                        <Grid item >
                        <Grid container justifyContent={'space-between'}> 
                        <Grid item>
                        <CustomTypography variant="body2" fontSize='0.8rem' color='textGrey'>{`${t("form.product.shipping.label")}`}</CustomTypography>
                        </Grid>
                          <Grid item >
                          <CustomTypography variant="body2" fontSize='0.8rem' color='textGrey'>{` ${formarttoPiCurrency(item.product.shippingFees)}`}</CustomTypography>
                          </Grid>
                        </Grid>
                        </Grid>


                        <Grid item marginTop={1}>
                        <Grid container justifyContent={'flex-end'} spacing={2}> 
                        <Grid item>
                        <CustomTypography variant="body2" fontSize='0.8rem' color='primary'>{`${t("seller")}`}</CustomTypography>
                        </Grid>
                          <Grid item >
                          <CustomTypography /* border='1px solid green' borderRadius={4} padding={'2px 4px'} */ variant="body2" fontSize='0.8rem' color='textGrey'>{` ${item.product.storeTag}`}</CustomTypography>
                          </Grid>
                        </Grid>
                        </Grid>

                      </Grid>
                  </Grid>
                 </Grid>
              </Grid>
            ))}
        </Grid>

      </div>
    )
  }
  const ListItemSecondaryContent= ({item,key})=>{
    return(<div key={'list-item-sec-0'}  className={classes.secondaryItemContent}>
      <div key={'list-item-sec-1'}  className={classes.measuremenItem}><span>Back</span><span>{item.measurement.back}</span></div> 
      <div key={'list-item-sec-2'}  className={classes.measuremenItem}><span>Chest</span><span>{item.measurement.chest}</span></div> 
      <div key={'list-item-sec-3'}  className={classes.measuremenItem}><span>Length</span><span>{item.measurement.shirtLength}</span></div> 
      <div key={'list-item-sec-4'}  className={classes.measuremenItem}><span>Sleeve</span><span>{item.measurement.sleeve}</span></div> 
      <div key={'list-item-sec-5'}  className={classes.measuremenItem}><span>Trouser length</span><span>{item.measurement.trouserLength}</span></div> 
      <div key={'list-item-sec-6'}  className={classes.measuremenItem}><span>Waist</span><span>{item.measurement.waist}</span></div> 
      <div key={'list-item-sec-7'}  className={classes.measuremenItem}><span>Thigh</span><span>{item.measurement.thigh}</span></div> 
      <div  key={'list-item-sec-8'} className={classes.measuremenItem}><span>Bust</span><span>{item.measurement.bust}</span></div> 

      {/* Chest:${item.measurement.chest} Length:${item.measurement.shirtLength} Sleeve:${item.measurement.sleeve} Trouser Length:${item.measurement.trouserLength} Waist:${item.measurement.waist} Thigh:${item.measurement.thigh} Bust:${item.measurement.bust} */}
    </div>)
  }
    return (
      <div  >   

      <CustomTypography style={{marginLeft:5}} variant="h6" >{t("checkout.ordersummary")}</CustomTypography>
     
           
     {cart!==undefined?
      <Grid container justifyContent={'space-between'} direction='column'
      sx={{ width: '100%',border:'0px solid',padding:1}}
      
    > 
        <Grid item >
        <SummaryItems items={items}/>
        </Grid>
       
        <Grid item >
       <hr style={{color:'darkgray'}} />
        </Grid>  
        <Grid item >
          <Grid container justifyContent={'space-between'} direction={'column'}>
            <Grid item >
             <Grid container justifyContent='space-between' padding={0.5}>
                 <Grid item >
                  <CustomTypography fontSize={'0.9rem'}>{t("checkout.subshipping")}</CustomTypography>
                  </Grid>
                  <Grid item >
                  <CustomTypography fontSize={'0.9rem'}>{`π${convertValueFromExponent(cart.subfees)}`}</CustomTypography>
                   </Grid>
              </Grid>
            </Grid>

            <Grid item >
             <Grid container justifyContent='space-between' padding={0.5}>
                 <Grid item >
                  <CustomTypography fontSize={'0.9rem'}>{t("checkout.subtotal")}</CustomTypography>
                  </Grid>
                  <Grid item >
                  <CustomTypography fontSize={'0.9rem'}>{`π${convertValueFromExponent(cart.subtotal)}`}</CustomTypography>
                   </Grid>
              </Grid>
            </Grid>


            <Grid item >
             <Grid container justifyContent='space-between' padding={0.5}>
                 <Grid item >
                  <CustomTypography fontSize={'0.9rem'}>{t("checkout.Total")}</CustomTypography>
                  </Grid>
                  <Grid item >
                  <CustomTypography color="secondary" fontSize={'0.9rem'}>{`${formarttoPiCurrency(cart.subtotal+cart.subfees)}`}</CustomTypography>
                   </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
       {/*  <ListItem key={'fees'} style={{padding:'2px 0'}}>
            <ListItemText primary={t("checkout.subshipping")}/>
            <CustomTypography variant="body2" >{`π${convertValueFromExponent(cart.subfees)}`}</CustomTypography>
          </ListItem>
          <ListItem key={'subtotal'} style={{padding:'2px 0'}}>
            <ListItemText primary={t("checkout.subtotal")}/>
            <CustomTypography variant="body2" >{`π${convertValueFromExponent(cart.subtotal)}`}</CustomTypography>
          </ListItem>
        <ListItem key={'total'} style={{padding:'6px 0'}}>
            <ListItemText primary={t("checkout.Total")}/>
            <CustomTypography variant="subtitle1" color="secondary" >{`${formarttoPiCurrency(cart.subtotal+cart.subfees)}`}</CustomTypography>
          </ListItem> */}
    </Grid>:setRefresh(!refresh)
      }
    </div> 
  )
}

export default Review
