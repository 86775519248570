import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
   /*  backgroundColor: theme.palette.background.default, */
    padding: 0,
    border:'0px solid',
    width:'100% !important',
    height:'auto',
    [theme.breakpoints.down('xs')]:{
      height:'75vh',
      padding:2,
      marginBottom:45
    },
    paddingBottom:10,
    overflow:'scroll',
    link: {
      textDecoration: 'none',
      color:'var(--app-primary)'
    },
    shadeContainer: {
      width: '300px',
      height: '200px',
      background: `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.grey[200]})`, // Use MUI theme colors
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      padding: theme.spacing(4), // Use spacing units for padding
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      textAlign: 'center',
      fontSize: '24px',
    },
   /* margin: '0% 14%',
    [theme.breakpoints.down('xs')]:{
      margin: '0% 0%',

    }
    ,[theme.breakpoints.down('sm')]:{
      margin: '0% 0%',

    }
 */
  },

}));
