import React, { useState, useMemo } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

/* const allOptions = ["Option One", "Option Two", "Option Three", "Option Four"];
 */
export default function SearchableSelect({allOptions,defaultOption,onChange,Label,required,open, setOpen}) {
  const [selectedOption, setSelectedOption] = useState(allOptions[0]);
  const selectRef = React.useRef(null);

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => allOptions.filter((option) => containsText(option.name, searchText)),
    [allOptions, searchText]
  );



 
  const handleOnchange =(e)=>{
    setSelectedOption(e.target.value)
    onChange(e)
  }


  /* const handleClick = () => {
    if (selectRef.current) {
      selectRef.current.openMenu();
    }
  }; */
  //handleClick(selectRef)
  return (
    <Box sx={{ m: 0 ,width:'100%'}}>
             
      <FormControl fullWidth>
      { /*   <InputLabel id="search-select-label">{Label}</InputLabel> */}
        <Select
          ref={selectRef}
          open={open}
          onClose={() => {setOpen(false);setSearchText("")}}
          onOpen={() => setOpen(true)}
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{ autoFocus: false }}
          labelId="search-select-label"
          id="search-select"
          value={selectedOption}
          defaultValue={defaultOption}
          label={Label}
          variant="outlined"
          required={required}
          fullWidth
          onChange={(e) => handleOnchange(e)}
         // onClose={() => setSearchText("")}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          //renderValue={() => selectedOption}
          InputProps={{  
            style: { padding: "4px 8px" }, // Reusable padding styles  
            
          }}  
          sx={{  
            "& .MuiOutlinedInput-root": {  
              padding: "4px", // Outer padding adjustment  
            },  
            "& .MuiInputBase-input": {  
              padding: "8px", // Inner padding adjustment  
              fontSize: "12px", // Adjust font size  
            },  
           
          }} 
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem key={0} value={'All'}>
              {'All'}
            </MenuItem>
          {displayedOptions.map((option, i) => (
            <MenuItem key={option.cid} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
