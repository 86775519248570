import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  root:{
    backgroundColor:'var(--app-secondary)',
   /* backgroundImage:'linear-gradient(to bottom right, var(--app-primary),  var(--app-primary))',
     backgroundImage:'linear-gradient(to bottom right,darkgrey,snow )'*/
    paddingTop:'10px',
    borderRadius:'2px',
    padding:'2px',
    border: `0px solid #2196f3`,
    [theme.breakpoints.down('xs')]:{
          /* marginBottom:'10vh', */
         padding:0,
    }
  },
 storeImg:{
    height:{xs:25,lg:30},
    border:'0px solid var(--app-primary)',
    backgroundColor:'var(--app-primary)',
    borderRadius: '50%',
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    aspectRatio: 1/1,
    /* marginTop:'8px',
    marginLeft:'2px' */
  }
  
}));
