/* eslint-disable no-unused-vars */
import {useEffect, useState,useCallback} from 'react'
import './product.css';
import {Box,Button,Grid,TextField,Typography,MenuItem,Select} from '@mui/material';
// import { Chart } from '../../../dashboard/components/charts/Chart';
 import { Publish,Edit } from '@material-ui/icons';
import QueryParams from '../../../QueryParams';
import { EditorState , convertToRaw,ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html'
// import TextEditor from '../newProduct/textEditor/TextEditor';
 import htmlToDraft from 'html-to-draftjs';
import {axiosClient,config} from '../../../services/axiosClient';
import CheckboxList from './checkList/CheckboxList'
import { convertValueFromExponent} from "../../../utils/Utils"
import {StoreCard} from  '../../../components'
import ImageGallery from './imageGallery/ImageGallery'
import {Countries} from '../../../assets/jsonData/countries'
import ContentTitleBar from '../../contentitlebar/ContentTitleBar';
import { myCld } from '../../../cloudinaryImage'
import Storecad from '../../storecard/Storedcad';
import CustomTextField from '../../customComponent/CustomTextField';

  const MesurementItem = ({itemval,index,name,onUpdateColors,})=>{
       
      const [val,setValue]=useState(itemval);
       return( <CustomTextField size='small' type="text" variant='outlined' className={`measurementItem ${name}`} placeholder="" value={val} key={index} onChange={(e)=>{setValue(e.target.value);onUpdateColors(name)}}  id={`${name}${index}`}/>)
       }
       
      const SizeMesurementItem = ({itemval,index,name,onUpdateSizes})=>{
        const [sval,setsValue]=useState(itemval);
        const onChange =(e)=>{
          console.log(e.target.value)
         setsValue(e.target.value);onUpdateSizes(name)
        }
     return( <CustomTextField size='small' variant='outlined' style={{padding:0}} type="text" className={`measurementItem ${name}`} placeholder="" value={sval} key={index} onChange={onChange}  id={`${name}${index}`}/>)
    }

export default function PmProduct({store,balance , setShowProgress,showprogress,authenticateToken,setMessage,setOpenSnackBar,setStoreProducts,storeProducts,handlegetTransactions,page,perPage,isStoreTransLoaded,setIsStoreTransLoaded,t,theme}) {

  const getCheckedCountries= (data,productScopes) =>{
    let checkedCountryscopes = []
   if(data.length >0){
    for(var i=0;i<data.length;i++){
      const obj ={name: data[i].name, countryCode: data[i].iso2}

       for(var j=0;j<productScopes.length;j++){
        if (JSON.stringify(productScopes[j])===JSON.stringify(obj)){
          checkedCountryscopes.push(data[i]) //assign whole country object
        }
       }
    }
  } 
  //console.log(checkedCountryscopes)

    return checkedCountryscopes
  
}


  
    const query=QueryParams();
    const [product,setProduct]= useState(JSON.parse(localStorage.getItem('product')));
    const [storeid]=useState(query.get('storeId'));
    const [storename]=useState(query.get('storeName'));
    const [productid]=useState(product._id); 
    const [productname]=useState(product.name);
    const [colors,setColors]=useState(product.color);
    const [sizes,setSizes]=useState(product.size);
    const [stock]=useState(product.stock)
    const [currentStock,setCurrentStock]=useState(product.stock.currentstock);
    const [addStock,setaddStock]=useState(0);
    const [shippingDuration,setShippingDuration]=useState(product.shippingDuration)
    const [active,setActive]=useState(product.active);
    const [lifespan,setLifespan]=useState(product.lifespan);
    const [price,setPrice]=useState(convertValueFromExponent(product.price));
    const [shippingFees,setShippingFees]=useState(convertValueFromExponent(product.shippingFees));
    const [monthlySales,setmonthlySales]=useState([]);
    const [productUpdated,setProductUpdated]=useState(false);
    //Editor
    const [description,setDescription]=useState(product.description);
    const blocksFromHtml = htmlToDraft(product.description);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    const [editorstate,setEditorState]=useState(editorState);
    const [showForms,setShowForms]=useState(true)
    const [isSalesLoaded,setIsSalesLoaded]=useState(false);
    const [showChecklist,setShowCheckList]=useState(false)
    const[isCountriesloaded,setIscountriesLoaded]=useState(false)
    const [checkedCountries,setcheckedCountries] =useState(getCheckedCountries(Countries,product.countryScope))
    const [shippingarea,setShippingarea]=useState('')
    const [countryScope,setCountryScope]=useState([])
    const [countries,setCountries]=useState([]);
    const [url]=useState( `https://www.daabiamall.com/proceedcheckout?storeId=${storeid}&productId=${productid}`)
    const[user]=useState(JSON.parse(localStorage.getItem('user')));

      const removeLastIndex = (values) => {
          let arr=[...values];
       if (arr.length >=2){
          arr.pop(values.length-1);
          // console.log(values);
          }

        return arr;
      }
      const s3Image = (images) =>{
        try{
         
          var s3img = images[0].url ? images[0].url:''
          return s3img
        }catch(err){
          console.log(err)
        }
      }
      const onEditorStateChange = (editorstate)=>{
        //console.log(editorstate.value)
        const htmlcontent=draftToHtml(convertToRaw(editorstate.getCurrentContent()))
        setEditorState(editorstate)
        setDescription(htmlcontent);
        
    }
     const handleonEditClicked=()=>{
        setShowForms(!showForms);
     }
      const getValues =(classname)=>{
          let values =[];
        let elems =  document.getElementsByClassName(classname);
       // console.log(elems[0].children[0].firstChild.value)

           for(let i=0;i<elems.length;i++){
                 if (elems[i].value!==""){
                    values.push(elems[i].children[0].firstChild.value)
                    console.log(elems[i].children[0].firstChild.value)
                 }
               
             
           }
           return values;
      }
      const getCountryScopes = (data) =>{
        let cScopes = [] //get country scopes list  
        //console.log(cScopes)

       if(data.length >0){
        for(var i=0;i<data.length;i++){ //add countries which are not included already
            const obj ={name: data[i].name, countryCode: data[i].iso2}
      
           cScopes.push(obj)

        }
       

       //setCountryScope((countryscope) => [...countryscope,...cScopes]);
       setCountryScope(cScopes);
       return cScopes

       }else{
         return cScopes
       }
    }
      const handleUpdate=(e)=>{
            e.preventDefault();
            setShowProgress(true)
        
            
            editProduct().then((response)=>{
               setShowProgress(false)
              
                    
                try{
                 if(response.data.status===200){
                     setProduct(response.data.product)
                     const editedProduct=response.data.product
                     storeProducts.map(p => p._id !==editedProduct._id ? p :editedProduct) 
                     setStoreProducts(storeProducts.map(p => p._id !==editedProduct._id ? p :editedProduct) )
                     const product=response.data.product
                     localStorage.setItem('product',JSON.stringify(product))
                     setCurrentStock(product.stock.currentstock);
                     setPrice(convertValueFromExponent(product.price));
                     setShippingFees(convertValueFromExponent(product.shippingFees))
                     setActive(product.active);
                     setLifespan(product.lifespan);
                     setColors(product.color);
                     setSizes(product.size);
                     setShippingDuration(product.shippingDuration)
                     setDescription(product.description)
                     setProductUpdated(!productUpdated)

                      setMessage({body:'product updated successfully',severity:'success'})
                     setOpenSnackBar(true) /* */
                 }else{
                  authenticateToken(user.user)
                 }
             setaddStock(0);
               
                }catch(error){
                  console.log(error)
                }

            });
      }

      const editProduct =()=>{
        const url = `${process.env.REACT_APP_SERVER_URL}/products/${productid}`;
      /*   const colval=getValues('color');
        console.log(colval);
        const sizeval=getValues('size'); */
        let countryscope = getCountryScopes(checkedCountries); //get country names from selected countries
        console.log(countryscope)
        
        const body={
                 productId:productid,
                 price:price,
                 shippingFees:shippingFees,
                 shippingDuration:shippingDuration,
                 description:description,
                 addstock:addStock,
                 stock:stock,
                 active:active,
                 lifespan:lifespan,
                 color:colors,
                 size:sizes,
                 countryScope:countryscope,
                 shippingArea:shippingarea
                 
        }
        const accessToken =localStorage.getItem('accessToken')
        const config = {
            headers: {
                'auth-token':
                  user.auth_token,
                  Authorization:`Bearer ${accessToken}`

              },
        }
        return axiosClient.patch(url, body,config)
      
      };
      const onUpdateColors =(name)=>{
         setColors(getValues(name));
      }
      const onUpdateSizes =(name)=>{
        setSizes(getValues(name));
     }
    

     const getCountries =  useCallback( async() => {

      // if (!mountedRef.current) return null ;

     if (!isCountriesloaded){
      try{
        const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axiosClient.get(url,config).then((response)=>{
              setCountries(response.data.countries) 
              setCountryScope(product.countryScope)
             }
        )
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    },[isCountriesloaded,product.countryScope]);
  
    useEffect(()=> {
     
   //  addresses.length >0 ? setShowAddresses(true):setShowAddresses(false);
   if (!isStoreTransLoaded){
    handlegetTransactions(page,perPage)
   }
     if (!isCountriesloaded) {getCountries();}
      return ()=>{
        setIscountriesLoaded(true)
        setIsStoreTransLoaded(true)
       // mountedRef.current=false;
      };

    },[getCountries, handlegetTransactions, isCountriesloaded, isStoreTransLoaded, product.countryScope, balance, setIsStoreTransLoaded, page, perPage]);
       
          
      
      useEffect(()=>{
        const handlegetSales = async ()=>{
          // console.log("get cart"+userid)
          
            getSales().then((response) => {

              if (response.status===200){
                try{
                 
                      setmonthlySales(response.data)
                  
                }catch(err){
                  console.log(err)
                }
              }
            })
          
        }
        
        
          
        
          const getSales =()=>{
             
            const url = `${process.env.REACT_APP_SERVER_URL}/analytics/transactions/product/sales/monthly/${product.storeId}/${product._id}`;
            
            return axiosClient.post(url,{year:2022},config)
          
          };

          if(!isSalesLoaded){
            handlegetSales()
          }

         return  () =>{
          setIsSalesLoaded(true)
         }
      },[isSalesLoaded, monthlySales, product])
    return (
        <div className="product">
          <Storecad store={store} balance={balance} t={t} theme={theme}/>
         {/*   <div className='storeCurrencyLabel'>
           <span>{storename}</span>
            <span>{` Local currency: ${store.currency}`}</span>
           </div> */}
           
            <ContentTitleBar store={store} pagetitle={t("form.product.title")} buttons={ store.status==='Verified' ? [{title:t("transactions.title"),link:`/transactions?`},{title:t("form.addnew"),link:`/new-product?storeId=${storeid}&storeName=${storename}`},{title:t("form.product.title_prural"),link:`/product-list?storeId=${storeid}&storeName=${storename}`}]:[]}/>
          <form  onSubmit={handleUpdate}>
            <Box  sx={{backgroundColor:'#fff',paddingLeft:{xs:1,sm:1,md:1,lg:0},paddingRight:{xs:1,sm:1,md:1,lg:1}}}>
            <Grid container justifyContent='space-between'>
                   {/*  <Grid item xs={12} sm={12} md={7} lg={7} >
                      <div className="pm-productInfoTopWrapper">
                      <Chart data={monthlySales.monthlySales} datakey="Monthly Sales"  title="Sales Performance"/> 
                      </div>
                    
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Grid container justifyContent={'space-between'}>
                          <Grid item xs={12}>
                          <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                          <Grid item xs={2}>
                          <img src={process.env.REACT_APP_WEB_IMAGE_LOCATION === 's3' ?s3Image(product.s3Image) : myCld.image(`${product.image[0].public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()} alt="" className="productInfoImg" /> 
                          </Grid>
                          <Grid item xs={10}>
                          <Typography variant='h6' fontSize={'1rem'}>{productname}</Typography>

                            </Grid>

                          
                        </Grid>
                          </Grid>
                         <Grid item xs={12}>
                   <Grid container spacing={2} padding={2} direction={'column'}>
         <Grid container>
      <Grid item xs={6}>
        <span className="productInfoKey">id:</span>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <span className="productInfoValue">{" " + product._id}</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item xs={6}>
        <span className="productInfoKey">{t("form.product.sales.label")}:</span>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <span className="productInfoValue">
              {monthlySales ? monthlySales.count : "0"}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item xs={6}>
        <span className="productInfoKey">{t("form.product.active.label")}:</span>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <span className="productInfoValue">{active}</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item xs={6}>
        <span className="productInfoKey">{t("form.product.stock.label")}:</span>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <span className="productInfoValue">{currentStock}</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item xs={6}>
        <span className="productInfoKey">{t("form.product.price.label")}:</span>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <span className="productInfoValue">
              {`π${convertValueFromExponent(product.price)}`}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item xs={6}>
        <span className="productInfoKey">{t("form.product.shipping.label")}:</span>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <span className="">
              {`π${convertValueFromExponent(product.shippingFees)}`}
            </span>
          </Grid>
        </Grid>
    </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={6}>
        <span className="productInfoKey">{t("form.product.shipping.duration.label")}:</span>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <span className="">
              {`${product.shippingDuration}`}
            </span>
          </Grid>
        </Grid>
    </Grid>
    </Grid>
                   </Grid>
                         </Grid>
                        
                         {/*  <Grid item>
                          <Edit onClick={handleonEditClicked}/>

                          </Grid> */}

                        </Grid>
                         <div className="pm-productInfoTop">
                       {/*  <img src={`${product.image[0].secure_url}`} alt="" className="productInfoImg" /> */}
                       
                      
                      
                    </div>
                   
                   
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                     <Grid container justifyContent={'space-between'} spacing={2}>
                     <Grid  item  xs={12}>
                       <label>{t("form.product.title")}</label>
                        <CustomTextField type="text" variant='outlined' className='productameinput' fullWidth value={product.name} onChange={()=>{}} placeholder="Apple Airpod"/>
                          
                        </Grid>
                        <Grid  item  xs={12}>
                        <label htmlFor="validationTextarea">{t("form.product.description.label")}</label>
                        {/*                          <TextEditor onEditorStateChange={onEditorStateChange} editorstate={editorstate}/>
                        */}                       <CustomTextField variant='outlined'   rows={3} multiline fullWidth onChange={(e)=>      {setDescription(e.target.value)}} value ={description}/>
                        </Grid>
                     </Grid>
                   </Grid>
                </Grid>
        
            {/* <Grid container>
                <Grid item>
             
                </Grid>
            </Grid> */}
          {/*  { showForms ? */} <Grid item>
               
               
                    
                <Grid container justifyContent='space-between' >
                  
                   <Grid item xs={12} sm={6}md={6} lg={6} >
                   <Grid container padding={2}>
                   <ImageGallery handleImages={()=>{}} productImages={product.image} productS3Images={product.s3Image && product.s3Image.length ? product.s3Image:[{url:''},{url:''},{url:''}]} productId={product._id} label={t("newproduct.gallery")} storeProducts={storeProducts} setStoreProducts={setStoreProducts}/>
                   </Grid>
                    </Grid>
                  
                    <Grid item xs={12} sm={6}md={6} lg={6} >
                       <Grid container padding={2} justifyContent='flex-start' direction={'column'} >

                        <Grid item >
                           <Grid container justifyContent={'space-between'} spacing={1}>
                         <Grid item xs={6}>
                        <div className='formItem'>
                         <label>{t("form.product.price.label")}</label>
                         <CustomTextField variant='outlined'type="number" placeholder="0.0001"value={price}onChange={(e)=>{setPrice(e.target.value)}}/>
                       </div>
                        </Grid>
                          <Grid item xs={6}>
                          <div className='formItem'>
                        <label>{t("form.product.shipping.label")}</label>
                        <CustomTextField variant='outlined' type="number" id="shippingfees" value={shippingFees}   placeholder="0.00000012" onChange={(e)=>{setShippingFees(e.target.value)}}/>
                        </div>
                        </Grid>
                              </Grid>
                        </Grid>
                      
                        <Grid item >
                           <Grid container justifyContent={'space-between'} spacing={1}>
                                <Grid item xs={6} >
                            <div className='formItem'>
                        <label>{t("form.product.stock.label2")}</label>
                        <CustomTextField variant='outlined' type="text" id="stock" value={addStock}   placeholder="0" onChange={(e)=>{setaddStock(e.target.value)}}/>
                        </div>
                                </Grid>
                                <Grid item xs={6}>
                          <div className='formItem'>
                        <label>{t("form.product.active.label")}</label>
                        <Select variant="outlined" value={active} name="country"  required fullWidth onChange={(e)=>{setActive(e.target.value)}}
                          sx={{  
                            "& .MuiOutlinedInput-root": {  
                              padding: "4px", // Outer padding adjustment  
                            },  
                            "& .MuiInputBase-input": {  
                              padding: "8px", // Inner padding adjustment  
                              fontSize: "12px", // Adjust font size  
                            },  
                          }}  
                          
                          >
                    <MenuItem key={0} value={'yes'}>{t("form.product.specification.positive")}</MenuItem>
                    <MenuItem key={1} value={'no'}>{t("form.product.specification.negative")}</MenuItem>

                   {/*  {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{c.name}</MenuItem>
                   ))} */}
                   </Select>     
                       {/*  <select name="active" id="" className="active" onChange={(e)=>{setActive(e.target.value)}} value={active}>
                           <option selected='selected'>{product.active}</option> 
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select> */}
                        </div>
                        </Grid>
                              </Grid>
                        </Grid>


                        <Grid item >
                           <Grid container justifyContent={'space-between'} spacing={1}>
                         
                                <Grid item xs={6}>
                          <div className='formItem'>
                        <label>{t("form.product.productlife.label")}</label>
                        <Select variant="outlined" value={lifespan} name="country"  required fullWidth onChange={(e)=>{setLifespan(e.target.value)}}
                          sx={{  
                            "& .MuiOutlinedInput-root": {  
                              padding: "4px", // Outer padding adjustment  
                            },  
                            "& .MuiInputBase-input": {  
                              padding: "8px", // Inner padding adjustment  
                              fontSize: "12px", // Adjust font size  
                            },  
                          }}  >
                    <MenuItem key={0} value={'New Product'}>{t("form.product.productlife.option1")}</MenuItem>
                    <MenuItem key={1} value={'Used Product'}>{t("form.product.productlife.option2")}</MenuItem>
                   </Select>     
                     
                        </div>
                        </Grid>

                        <Grid item xs={6} >
                            <div className='formItem'>
                        <label>{t("form.product.shipping.duration.label")}</label>
                        <CustomTextField variant='outlined' type="text" id="stock" value={shippingDuration}   placeholder="0" onChange={(e)=>{setShippingDuration(e.target.value ? parseInt(e.target.value):1)}}/>
                        </div>
                                </Grid>
                              </Grid>

                        </Grid>

                        
                       
                        
                       
                       
                        <Grid item xs={12}  sx={{marginTop:2}}>
                           <label>{t("form.product.colorspecification.label")}</label>
                           <Grid container justifyContent={'flex-start'} spacing={1} paddingTop={1}>
                           {   
                             colors.map((color,index)=>{
                               return( <Grid key={index} item xs={3} marginBottom={0.5}>
                                <MesurementItem itemval={color} index={index} name='color' onUpdateColors={onUpdateColors}/>
                               </Grid>)
                             })
                          }
                           </Grid>
                        
                          <div className='actions'>
                          <Button variant="btn-outlined" id='action-btn-size-remove' size='small' onClick={()=>{setColors([...removeLastIndex(colors)])}}>-</Button> 
                           <Button variant="btn-outlined" id='action-btn-color-add' size='small' onClick={()=>{setColors([...colors,""])}}>+</Button>
                          </div>
                        </Grid>

                        <Grid item>
                           <label>{t("form.product.sizespecification.label")}</label>
                           <Grid container justifyContent={'flex-start'}paddingTop={1} spacing={1}>
                           {   
                             sizes.map((size,index)=>{
                               return( <Grid  key={index} item xs={3} marginBottom={0.5}>
                                <SizeMesurementItem  itemval={size} index={index} name='size' onUpdateSizes={onUpdateSizes}/>
                               </Grid>)
                             })
                          }
                           </Grid>
                           
                          <div className='actions'>
                          <Button variant="btn-outlined" id='action-btn-size-remove' size='small' onClick={()=>{setSizes([...removeLastIndex(sizes)])}}>-</Button> 
                           <Button variant="btn-outlined" id="action-btn-size-add" size='small' onClick={()=>{setSizes([...sizes,""])}}>+</Button>
                          </div>
                        </Grid>
                  
               <Grid item xs={12} sm={12} md={10} lg={10}>
               <div className="client-addProductItem">
               <Grid container justifyContent='space-between' direction={"column"}>
                <Grid item >
                <label>{t("form.product.shippingarea.label")}</label>
                </Grid>
               <Grid>
                <Typography variant='body2' sx={{fontSize:'0.70rem'}}>{t("form.product.shippingarea.hint")}</Typography>
               </Grid>
               </Grid>
               <Grid container >
               <Grid item >
                    
                </Grid>
               </Grid>
                
                   <CustomTextField  rows={2}  multiline fullWidth  placeholder={t("form.product.shippingarea.placeholder")} variant='outlined'  onChange={(e)=>  {setShippingarea(e.target.value)}} value ={product.shippingArea ? product.shippingArea :''}/>
                
                </div>
               </Grid>
              {/*  <div className="formItem">
               <Grid container justifyContent='space-between'>
                <Grid item >
                <label>Country of sale</label>
                </Grid>
               
                <Grid item >
                <Edit onClick={()=>{setShowCheckList(!showChecklist)}}/>
                </Grid>
               </Grid>
               <Grid container>
               <Grid item >
                    {<Typography variant='body2' color='darkgrey'>
                      {
                        checkedCountries.map((item)=>{
                            return `${item.name},`
                        })
                      }</Typography>}
                </Grid>
               </Grid>
                {
                   showChecklist ? <div className='checkList'>
                   <CheckboxList data={countries} checkedCountries={checkedCountries} setcheckedCountries={setcheckedCountries}  />
                 </div>:''
                }
                </div> */}
                       
                        {/* <div className="productUpload">
                              <img src={`${product.image[0].secure_url}`} alt="" className="productUploadImg" />
                            <label htmlFor="file" style={{display:'block'}}>
                                <Publish/>
                            </label>
                            <input type="file" id="file" style={{display:"none"}} />
                 
                        
                        </div> */}
                          <div className='editButtonContainer'>
                        <Button sx={{textTransform:"none"}} variant='outlined' color='primary' type="submit" fullWidth >{t("form.update")}</Button>
                        </div>
                       </Grid>
                    </Grid>
               </Grid>
                   {/*  <div className="productFormLeft">                    
                  
                        <div className='formItem'>
                          
                        </div>
                    </div>
                    <div className="productFormMiddle">
                   
                      
                     
                    </div>
                    <div className="productFormRight">
                
                           
                       
                    </div> */}
              
        </Grid> 
        </Box>
         </form>
           {/*  </div>:''} */}
        </div>
     );
}