import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Grid} from '@mui/material';
import {CustomPreloader } from 'react-preloaders2';
/* import {  createTheme} from '@mui/material/styles';
 */import { randomDigit } from './utils/Utils';
import { Typography } from '@material-ui/core';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import common_zh from "./translations/zh/common.json";
import common_ar from "./translations/ar/common.json";
import common_ko from "./translations/ko/common.json";
import MaintainanceMode from './components/maintainancemode/MaintainanceMode';

const randomhighlights =()=>{
   var highlights= ['... loading','Welcome to Daabia Mall, a smooth and seamless Web3 e-commerce experience','Daabia Mall creates an Avenue for buyers and sellers']
  const rand = parseInt(randomDigit(2))
  
  // console.log(highlights[rand])
  return `${highlights[rand]}`
}
const welcometext = randomhighlights()
const MaintenanceMode = true //process.env.REACT_APP_MAINTENANCE_MODE

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      de: {
          common: common_de
      },
      fr: {
        common: common_fr
    }, zh: {
      common: common_zh
}, ar: {
  common: common_ar
}, ko: { 
  common: common_ko
},
  },
});
ReactDOM.render(
 // <React.StrictMode>
  <BrowserRouter> 
  <I18nextProvider i18n={i18next}>
   <App />
</I18nextProvider>
      {/*<Lines />*/}
  
     {/* <CustomPreloader>
          <Grid container justifyContent='center' alignContent={'center'}>
          <Grid item padding={2} >
          <Typography sx={{fontSize:'0.9rem'}} align='center'>{welcometext}</Typography>
          </Grid>
          </Grid>
         </CustomPreloader> */} 
         
         </BrowserRouter>
  //</React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
