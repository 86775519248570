import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{/* marginBottom:'10vh', */
         padding:40,
         height:'90vh',  
         overflow:'unset',
         [theme.breakpoints.down('xs')]:{
          padding:'0%',
          height:'94.4vh',
          overflow:'unset',
          backgroundColor:'#fff'

        },
  },
 content:{
   
    backgroundColor:'white',
    padding:'1%',
   /*  marginBottom:'10px' */
   [theme.breakpoints.down('xs')]: {
   
    border:'0px solid',
    padding:'1%',
    },
 },
  toolbar: theme.mixins.toolbar,
  title: {
    marginTop: '1vh',
    [theme.breakpoints.down('xs')]:{
      marginTop: 0,
    },
  },
  emptyButton: {
    minWidth: '150px',
    [theme.breakpoints.down('xs')]:{
      marginBottom: '5px',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '20px',
    },
  },

  price:{
    color:'red',
    fontWeight:0,
    fontSize:'0.7rem'
 },
  checkoutButton: {
    padding:2,
    fontSize:'0.9rem !important'
  },

  link:{
    textDecoration: 'none',
    color:'var(--app-primary)'
  },
  notice:{
    padding:5
  },cartlistcontainer:{
    overflow:'scroll',
    height:'100vh',
    paddingLeft:16,
    paddingRight:16,
    paddingBottom:25,
    [theme.breakpoints.down('xs')]:{
      height:'65vh',
      width:'100%',
      border:'0px solid',
      paddingLeft:'0px !important',
      paddingRight:'0px !important',
    marginBottom:0
    },cartdetails:{
     border:'0px solid !important' ,
    }, /*
  },
  cardDetails:{
    display: 'flex',
    [theme.breakpoints.down('xs')]:{
      flexDirection:'column',
      width:'100%'
    }, /*  marginTop: '1vh', */  nottom: '3vh',
    width: '100%',
    justifyContent:'space-between',
    [theme.breakpoints.up('xs')]:{
      justifyContent:'space-around',

    },
    border:'0px solid'
  },
  checkoutButtons: {
    width:'50%',
    [theme.breakpoints.down('xs')]:{
      width:'98%'
    },
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    border:'0px solid'
  },
  filledcart:{
    border:'0px solid',
    height:'100vh',
    overflow:'hidden',
    marginBottom:'2vh',

    [theme.breakpoints.down('xs')]: {
      height:'86vh',
      border:'0px solid',
      overflow:'scroll',
      width:'100%',
      marginBottom:'0vh',
    

    },
  },                                    
  cartdetailsWrapper:{
       
    position:"sticky",
    top: "1rem",
    border:'0px solid',
    [theme.breakpoints.down('xs')]: {
    position: "relative",
    top:1,
    border:'0px solid',
    

    },
   /*  minWidth: "275" */
},
}));
