import React from 'react'
import {Grid,Typography} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useHistory} from 'react-router-dom'

const NavBarNavigation = ({title,color}) => {

    const history =useHistory()

  return (
       <Grid container justifyContent='flex-start'  spacing={1} marginBottom={1} border={0}  padding={0.8} height={50}>
         <Grid item  xs={1} onClick={()=>{history.goBack()}}>
                       <ArrowBackIcon style={{marginTop:'3px'}} htmlColor={color?color:'darkgray'}/>
                    </Grid>
         <Grid item xs={10} justifyContent={'center'}>
          <Grid container justifyContent={'flex-start'}>
              <Grid item >
              <Typography variant="h6"  fontWeight={600} color={color?color:'darkgray'}>{title}  </Typography> 
              </Grid>
          </Grid>
         </Grid>
         <Grid item  xs={3} onClick={()=>{}}>
                       
                    </Grid>
        </Grid>
  )
}

export default NavBarNavigation
