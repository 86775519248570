import React,{useState} from 'react';
import './specs.css';
import {Button,Grid} from '@mui/material'
import CustomTextField from '../../../customComponent/CustomTextField';

const MesurementItem = ({key,itemval,index,name,onUpdateColors})=>{
  const [val,setValue]=useState(itemval);
 return( <CustomTextField size='small' variant='outlined' type="text" className={`specsmeasurementItem ${name}`} placeholder="" value={val} key={index} onChange={(e)=>{setValue(e.target.value);onUpdateColors(name)}}  id={`${name}${index}`}/>)
}

const SizeMesurementItem = ({itemval,index,name,onUpdateSizes})=>{
  const [sval,setsValue]=useState(itemval);
return( <CustomTextField size='small'  variant='outlined' type="text" className={`specsmeasurementItem ${name}`} placeholder="" value={sval} key={index} onChange={(e)=>{setsValue(e.target.value);onUpdateSizes(name)}}  id={`${name}${index}`}/>)
}
const Specs = ({setColors,setSizes,t,resetSpecs,mcolors,setmColors,msizes,setmSizes}) => {
   /*  const[mcolors,setmColors] = useState([""]); //initiliaze color inputs 
    const[msizes,setmSizes]   = useState([""]); //initilaize size inputs
     */
  
const removeLastIndex = (values) => {
    let arr=[...values];
     arr.pop(values.length-1);
     console.log(values);
  return arr;
}

const getValues =(classname)=>{
    let values =[];
  let elems =  document.getElementsByClassName(classname);
     for(let i=0;i<elems.length;i++){
           if (elems[i].value!==""){
              values.push(elems[i].children[0].firstChild.value)
              console.log(elems[i].children[0].firstChild.value)
           }
         
       
     }
     return values;
}
const onUpdateColors =(name)=>{
    setColors(getValues(name));
    setmColors(getValues(name));
 }
 const onUpdateSizes =(name)=>{
  setSizes(getValues(name));
  setmSizes(getValues(name));
}

React.useEffect(()=>{
  if (resetSpecs){
    setmColors([]);
    setmSizes([]);
  }
},[resetSpecs])
  return (
    <Grid className='specs' justifyContent='center' container spacing={1} marginTop={1}>
                     <Grid item xs={12}>
                             
                     <label>{t("form.product.colorspecification.label")}</label> 
                       
                     </Grid>  
                         
                         <Grid item xs={12} sm={12} md={12} lg={12} >

                        
                           <Grid item >
                               <Grid container justifyContent={'space-between'} spacing ={0} >
                                 
                                  <Grid item  xs={6} sm={3}>
                                  <small>{t("form.product.colorspecification.hint")}</small>
                                  </Grid>
                    <Grid item  xs={4} sm={4}>
                         <Grid container justifyContent='space-between' className='action'>
                         <Grid item xs={4}>
                         <Button variant="outlined" sx={{padding:0}} id='action-btn-size-remove' size='small' onClick={()=>{setmColors([...removeLastIndex(mcolors)])}}>-</Button> 
                          </Grid>
                        <Grid item xs={4}>
                        <Button variant="outlined" sx={{padding:0}} id='action-btn-color-add' size='small' onClick={()=>{setmColors([...mcolors,""])}}>+</Button>
                        </Grid>


                          </Grid>
                    </Grid>

                               </Grid>
                             </Grid>
                           {/*  <Grid container justifyContent='flex-start' spacing={1} padding={'2px'} className='client-specsformItem' margin={'1px'}>
                            {   
                             mcolors.map((color,index)=>{
                               return( <Grid item xs={3} sm={3} md={3} lg={3}><MesurementItem itemval={color} index={index} name='color' key={`color-spec-${index}`} onUpdateColors={onUpdateColors}/></Grid>)
                             })
                          }
                            </Grid> */}
                             <Grid container justifyContent={'flex-start'} margin={1} padding={1} >
                             {  
                               mcolors.map((color,index)=>{
                                 return( <Grid item xs={3} sm={3} md={3} lg={3}><MesurementItem itemval={color} index={index} name='color'  onUpdateColors={onUpdateColors}/></Grid>)
                               })
                            }
                             </Grid>
                      
                          
                   </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}  >
                        <Grid item  xs={12} >
                                   <label>{t("form.product.sizespecification.label")}</label> 
                                  </Grid>
                           <Grid item xs={12}>
                               <Grid container justifyContent={'space-between'} spacing ={0} >
                                 
                                  <Grid item  xs={8} sm={3}>
                                  <small> 
                                    {t("form.product.sizespecification.hint")}</small>
                                  </Grid>
                      <Grid item  xs={4} sm={4}>
                        <Grid container justifyContent={'space-between'} className='action'>
                         <Grid item xs={4}>
                         <Button variant="outlined" style={{padding:0}} id='action-btn-size-remove' size='small' onClick={()=>{setmSizes([...removeLastIndex(msizes)])}}>-</Button> 
                          </Grid>
                          <Grid item xs={4} >
                          <Button variant="outlined" style={{padding:0}}id="action-btn-size-add" size='small' onClick={()=>{setmSizes([...msizes,""])}}>+</Button>
                            </Grid>
                          </Grid>
                                  </Grid>

                               </Grid>
                           </Grid>
                         
                         
                           
                          {      
                            /*<Grid container justifyContent='flex-start' spacing={1} padding={'2px'}className='client-specsformItem' margin={'1px'}></Grid>  msizes.map((size,index)=>{
                               return( <Grid item xs={3} sm={3} md={3} lg={3}>
                                <SizeMesurementItem key={`size-spec${index}`} itemval={size} index={index} name='size' onUpdateSizes={onUpdateSizes}/>
                               </Grid>)
                             }) */
                             <Grid container justifyContent={'flex-start'} margin={1} padding={1}>
                             {  
                               msizes.map((size,index)=>{
                                 return( <Grid item xs={3} sm={3} md={3} lg={3}>
                                  <SizeMesurementItem itemval={size} index={index} name='size' onUpdateSizes={onUpdateSizes}/>
                                 </Grid>)
                               })
                            }
                             </Grid>
                          }
                       
                        
                         
                        </Grid>
    </Grid>
  )
}

export default Specs



