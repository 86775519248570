/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import {Tab,Grid, Divider} from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import OrderItem from '../order/orderItem';
import { AdsTerra } from '../..';
import useStyles from './styles'
import { t } from 'i18next';

function OrderItemList(props){
    const {t,langcode,translateStatus, orders,handlegetStore,handleonReviewClick,ref,showAds, ...other } = props;

     var counter=1
    const isEven =(num,count)=>{
    counter +=1;
    var bool=false
    if (counter ===count){
      bool = num % 2 === 0 ? true : false
     counter=1
    }
  
    return bool
  }
    return   <Box style={{ bgcolor: 'background.paper', width: '100%' ,padding:0,border:'0px solid',height:'750vh',overflow:'scroll'}}>
     <Grid container justifyContent="center" spacing={0}  ref={ref} marginBottom={4}>
    { <>
      {
        orders.map((order,index)=>(
         <>
          <Grid item key={order._id} xs={12} sm={12} md={12} lg={12}>
          <OrderItem order={order} onReviewClick={handleonReviewClick} handlegetStore={handlegetStore} selectedItem={index} t={t} langcode={langcode} translateStatus={translateStatus}/>
          </Grid>
          <div style={{border:'0.5px solid darkgrey',width:'100%',margin:'2px'}}></div>
        { <>{ showAds ?isEven(index, 2) ?<Grid>
         <AdsTerra adkey='52f66475ed3ada3af8c5929ec857afd5' height={50} width={320}/> 

          </Grid>:'':''}</> 
          }

         </>

           ))

      }
    </>
    }
  </Grid></Box>
}
function TabPanel(props) {
  const { t,children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt:1 }}>
          <Typography variant='body2' >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,  textTransform: 'none',

  };
}

function tabBackground (index){
  if (index===0){
    return "var(--order-status-paid)"
  }else if (index===1){
    return "var(--order-status-shipped)"
  }else if (index===2){
    return "var(--order-status-recieved)"
  }else if (index===3){
    return "var(--order-status-complete)"
  }
}
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(0),
       padding:0,
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
      '&.MuiButtonBase-root-MuiTab-root.Mui-selected':{
       backgroundColor: '#fff'
}
    }),
  );
  const StyledTabs = styled((props) => (
    <Tabs sx={{boxShadow:'none'}}
      {...props}
      TabIndicatorProps={{ children: <span className="MiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: '100%',
      width: '100%',
      backgroundColor: 'var(--app-primary)',
     
    },
   "& .MuiTabs-root !important" :{
        boxShadow: 'none',
    }
  });
export default function OrderTabs({orders,onReviewClick,handlegetStore,selectedItem,isOrdersSorted,setisOrdersSorted,paidOrders,shippedOrders,recievedOrders,completedOrders,selectedTap, setSelectedTap,t,langcode,translateStatus}) {
  const theme = useTheme();
  const classes=useStyles()
 // const [value, setValue] = React.useState(0);

/*   const [paidOrders,setPaidOrders]= useState([]);
  const [shippedOrders,setShippedOrders]= useState([]);
  const [recievedOrders,setRecieveOrders]= useState([]);
  const [completedOrders,setCompletedOrders]= useState([]); */
 // const [allOrders,setallOrders]= useState([]);
  


  const handleChange = (event, newValue) => {
    setSelectedTap(newValue);
  };

  const handleChangeIndex = (index) => {
    setSelectedTap(index);
  };
   /* useEffect(()=>{
      if (!isOrdersSorted && orders.length >0){
        //setallOrders(orders)
        setPaidOrders(orders.filter(order =>      order.status.title==="Paid"))
        setRecieveOrders(orders.filter(order =>   order.status.title==="Recieved"))
        setShippedOrders(orders.filter(order =>   order.status.title==="Shipped"))
        setCompletedOrders(orders.filter(order => order.status.title==="Completed"))

      }
      
      return () => {
      setisOrdersSorted(true)
   } 
   },[isOrdersSorted, orders, setisOrdersSorted])
*/
   
  return (
    <Box sx={{ /* bgcolor: 'background.paper',,height:'90vh',overflow:'scroll' */ width: '100%' ,padding:0,border:0}}>
      
      <AppBar position="static" sx={{bgcolor:'white',boxShadow:0,width:'100%'}}>
        <StyledTabs
        
          value={selectedTap}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="orders tabs"
          
          
        > 
          <StyledTab label={translateStatus('Paid')}   {...a11yProps(0)}  />
          <StyledTab label={translateStatus("Shipped")}   {...a11yProps(1)} />
          <StyledTab label={translateStatus("Recieved")}  {...a11yProps(2)} />
          <StyledTab label={translateStatus("Completed")}  {...a11yProps(3)} />
         {/*  <StyledTab label={`Paid(${paidOrders.length})`}   {...a11yProps(0)}  />
          <StyledTab label={`Shipped(${shippedOrders.length})`}   {...a11yProps(1)} />
          <StyledTab label={`Recieved(${recievedOrders.length})`} {...a11yProps(2)} />
          <StyledTab label={`Completed(${completedOrders.length})`}  {...a11yProps(3)} /> */}
        </StyledTabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={selectedTap}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={selectedTap} index={0} dir={theme.direction} t={t}>
           {
            paidOrders.length > 0?  <OrderItemList orders={paidOrders}  onReviewClick={onReviewClick} handlegetStore={handlegetStore} selectedItem={selectedItem}  t={t} langcode={langcode} translateStatus={translateStatus}/>:''
           }
        </TabPanel>
       
        <TabPanel value={selectedTap} index={1} dir={theme.direction}>
        {
            shippedOrders.length > 0?  <OrderItemList orders={shippedOrders}  onReviewClick={onReviewClick} handlegetStore={handlegetStore} selectedItem={selectedItem}  t={t} langcode={langcode} translateStatus={translateStatus}/>:''
           }
       
        </TabPanel>
        <TabPanel value={selectedTap} index={2} dir={theme.direction}>
        {
            recievedOrders.length > 0?  <OrderItemList orders={recievedOrders}  onReviewClick={onReviewClick} handlegetStore={handlegetStore} selectedItem={selectedItem}  t={t} langcode={langcode} translateStatus={translateStatus}/>:''
           }
       
        </TabPanel>
        <TabPanel value={selectedTap} index={3} dir={theme.direction}>
        {
            completedOrders.length > 0?  <OrderItemList orders={completedOrders}  onReviewClick={onReviewClick} handlegetStore={handlegetStore} selectedItem={selectedItem} t={t} langcode={langcode} translateStatus={translateStatus}/>:''
           }
        
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
