import { Box, Card, Grid,IconButton,Typography} from '@mui/material'
import {ArrowBackOutlined} from '@material-ui/icons'
import React from 'react'
import useStyles from './styles'
import NotificationItem from './NotificationItem'
import ImpressionClickTrackerHOC from '../impressionClickTracker/ImpressionClickTrackerHoc'
import CloudTranslate from '../../translations/CloudTranslate'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Notification = ({axiosClient,config,notification,showNotification,setShowNotification,t,langcode})=>{
    const classes =useStyles()
    const [impressionItems,setImpressionItems]=React.useState([])
    const [message,setMessage]=React.useState([])
    const[showDetails,setshowDetails]=React.useState(false)
    let history = useHistory();

    const navigateLink =(link)=>{
      console.log("link = "+link)
         if (link){
          setshowDetails(!showDetails)
          setShowNotification(!showNotification)
        /*   history.push(`/${link}?productId=${product._id}&productName=${product.name}&storeId=${store._id}&storeName=${store.name}`);
 */
         }
    }
    const handleUpdateMessage = async (Id,storeId)=>{
   
    
      updateMessage(Id,storeId).then((response) => {
      // console.log(response.data);
       if (response.status===200){
      
       }
     }) 
 }
 
 
   
 
   const updateMessage =(Id,storeId)=>{
     
     const url = `${process.env.REACT_APP_SERVER_URL}/messages/${Id}`;
  
     return axiosClient.patch(url,  {
       messageId:Id,
       storeId:"storeId",
       userId:"user._d",
       
      
     },config)
   
   };

    const NotificationDetails =({notification,showDetails,setshowDetails})=>{
      console.log(notification)
      const[isnotifUpdated,setIsNotifUpdated]=React.useState(false)
                    
        React.useEffect(()=>{
         if (!isnotifUpdated){
          handleUpdateMessage(notification._id)
         }

         return ()=>{
           setIsNotifUpdated(true)
         }
        })
      return (
        <Box  className={`${classes.notification} ${ showDetails ? classes.notificationShow : classes.notificationHide} `} >
        <Grid container justifyContent='space-between' alignContent={'flex-start'} spacing={2} direction='column' >
          <Grid item xs={12} style={{backgroundColor:'white',zIndex:1}}>
             <Grid container justifyContent={'flex-start'} alignItems={'center'}>
             <Grid item xs={2}>
  
                    <IconButton onClick={()=>{setshowDetails(!showDetails)}} > <ArrowBackOutlined   style={{color:'darkgrey',fontWeight:600}} /></IconButton>
            </Grid>
            <Grid item  xs={10}>
                  <Typography variant='h5' fontWeight={600}>{t("notifications.title")}</Typography>
            </Grid>
             </Grid>
          </Grid>
        <Grid item xs={12} onClick={()=>{navigateLink(notification.message.link)}}>
              {
        notification.message.content  ? <Grid container justifyContent={'flex-start'} spacing={0} height={'auto'} border={0} marginTop={0}>
       
       <Card  key={`msg-item-card${0}`}  style={{backgroundColor: notification.isRead ?'#c1c1c121': "rgb(178 177 177 / 32%)"}} elevation={0}>
      <Grid   container justifyContent={'space-between'} direction={'column'} style={{borderRadius:'4px',minHeight:50,paddingTop:20, paddingBottom:20,paddingLeft:10,paddingRight:10}}>
      <Grid item xs={12} minHeight={40}>

       <Grid container justifyContent={'flex-start'}>
         <Grid item >
         {<CloudTranslate langcode={langcode}t={t} text={notification.message.title}/>}
         </Grid>
       </Grid>
     </Grid>
     <Grid item xs={12} minHeight={40}>
     <Typography  variant='body2' style={{fontSize:'0.9rem',color:'darkgrey'}}>{<CloudTranslate  langcode={langcode}t={t} text={notification.message.content}/>}</Typography>
     </Grid>
       </Grid>
    </Card>
            
        </Grid>:''
          }
        </Grid>
     </Grid>
       </Box>
      )
    }
/*  if (!isItemImpressionchecked && !notification.isRead){
           setImpressionItems( rev =>[...impressionItems,notification._id])
  */
   
/* const NotificationItem = ({notification,i}) =>{
    return (
       <Card style={{backgroundColor:'#c1c1c121'}} elevation={0}>
         <Grid key={i} container justifyContent={'space-between'} direction={'column'} style={{borderRadius:'4px',minHeight:41,padding:10}}>
         <Grid item xs={11} minHeight={40}>

          <Grid container justifyContent={'flex-start'}>
            <Grid item >
            {notification.message.title}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} minHeight={40}>
        <Typography  variant='body2' style={{fontSize:'0.9rem',color:'darkgrey'}}>{notification.message.content}</Typography>
        </Grid>
          </Grid>
       </Card>
    )
} */
const Container = ({notification,t})=>{
    return(
       <> {showDetails ? <NotificationDetails notification={message} setshowDetails={setshowDetails} showDetails={showDetails} />  : 
       
       <Box  className={`${classes.notification} ${ showNotification ? classes.notificationShow : classes.notificationHide} `} >
      <Grid container justifyContent='space-between' alignContent={'flex-start'} spacing={2} direction='column' >
        <Grid item xs={12} style={{backgroundColor:'white',zIndex:1}}>
           <Grid container justifyContent={'flex-start'} alignItems={'center'}>
           <Grid item xs={2}  onClick={()=>{setShowNotification(false)}}>

                  <IconButton onClick={()=>{setShowNotification(!showNotification)}} > <ArrowBackOutlined   style={{color:'darkgrey',fontWeight:600}} /></IconButton>
          </Grid>
          <Grid item  xs={10}>
                <Typography variant='h5' fontWeight={600}>{t("notifications.title")}</Typography>
          </Grid>
           </Grid>
        </Grid>
      <Grid item xs={12}>
            {
      notification.message &&  notification.message.length > 0 ? <Grid container justifyContent={'flex-start'} spacing={0} height={'80vh'} overflow={'scroll'} border={0} marginTop={0}>
     
        {
        notification.message.length >0?  notification.message.map((n,i)=>{
              return <Grid item xs={12} key={`msg-item-grid-${i}`} >
                     <Grid container justifyContent={'flex-start'} spacing={2} alignItems={'center'}>
                            <Grid item xs={12}>
                            
                           <NotificationItem key={`msg-item-${i}`}  notification={n} i={i} impressionItems={impressionItems}setImpressionItems={setImpressionItems} setMessage={setMessage} t={t} langcode={langcode} setshowDetails={setshowDetails} showDetails={showDetails}/>
    
                          
                
                            </Grid> 
                            <Grid item xs={12}  marginBottom={5}>
                                 <Grid container justifyContent='center'>
                                    <Grid item > <Typography color={'GrayText'}>{new Date(n.dateCreated).toDateString()}</Typography></Grid>
                                 </Grid>
                            </Grid>
                           
                     </Grid>
                   
                </Grid>
          })
          :"" 
        }
          
      </Grid>:''
        }
      </Grid>
   </Grid>
     </Box>}</>
    )
   /*  <Grid item xs={12}>
    <ImpressionClickTrackerHOC 
    clickEvent={`ADD-TO-CART`}
disableViewportTracking={false} handleUpdateMessage={handleUpdateMessage}>
  <div
data-tracker-id={`${n._id}`}
data-display-name={`msg-item-${i}`}
data-listing-id={`list-msg-item-${i}`}
data-item-isread={n.isRead}>
<NotificationItem key={`msg-item-${i}`}  notification={n} i={i} impressionItems={impressionItems}setImpressionItems={setImpressionItems} t={t} langcode={langcode}/>
</div>
  
    </ImpressionClickTrackerHOC>


  </Grid> */
  }
  return (
    <div >
        <Container notification={notification} t={t}/>
    </div>
  )
}

export default Notification
