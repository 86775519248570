import {makeStyles}from '@material-ui/core/styles';

export default makeStyles((theme)=>({
  root:{
      height:'100vh',
      maxWidth:'100%',
      minWidth:'100%',
      backgroundColor:'white',

  },
  storeimg:{
      height:74,
      width:74,
      border:`0px solid var(--app-primary)`,
      borderRadius:60,
      objectFit: 'cover'
  },
  buynow:{
    width:'10vw',
    [theme.breakpoints.down('xs')]:{
      width:'30vw',
 }}
}));