import React from 'react';
import { TextField, Grid,InputAdornment} from '@mui/material';

const PhoneCountryCodeTextField = ({ name, label,placeholder,variant,size,margin,required,register ,type,adormentValue,onChange}) => {
   
return (
   <Grid item xs={12} sm={12}>
       <TextField
         {...register(name)}
            fullWidth
            variant={`${variant}`}
            onChange={onChange}
            label={label}
            placeholder={placeholder}
            required={required}
            type={type !==null ?type:'' }
           /*  onFocus={()=>{onFocus()}}
            onBlur={()=>{onBlur()}} */
            InputProps={ {style: { padding: "4px 8px" }, // Custom padding for the input field 
              startAdornment: <InputAdornment position="start">{adormentValue}</InputAdornment>
            }}
            
            sx={{  
              "& .MuiOutlinedInput-root": {  
                padding: "4px", // Outer padding adjustment  
              },  
              "& .MuiInputBase-input": {  
                padding: "8px", // Inner padding adjustment  
                fontSize: "12px", // Adjust font size  
              },  
             
            }} 
            
            />
    </Grid>
 );
 }

export default PhoneCountryCodeTextField;