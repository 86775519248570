import * as React from 'react';
import Box from '@mui/material/Box';
import {Grid} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  height:'21vh',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90%',sm:'80%',md:'60%',lg:'20%'},
  bgcolor: 'snow',
  color:'#000',
  border: '2px solid var(--app-primary)',
/*   borderTopRightRadius: 12, 
 */  borderRadius: 4, 
  boxShadow: 24,
  p: 2,
};

export default function Medalalerts({open,handleClose,t}) {
 
   
  return (
    <div>
      <Modal 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
         <Grid container justifyContent='space-between'>
          <Grid item> <Typography id="modal-modal-title" fontSize={'0.8rem'} variant="h6" component="h2">
           {
            t('account.rewards.dailyrewards')
          }
          </Typography></Grid>
          <Grid item onClick={()=>handleClose()}>X</Grid>
         </Grid>

        {
      <Grid container justifyContent='space-between'   border={0} alignItems={'center'}>
         
                
          <Grid item xs={12} marginTop={1} >
                <Grid container justifyContent={'center'} alignItem='center' spacing={0.5} >
                    <Grid item xs={3} >
                    </Grid>
                    <Grid item  xs={6} >
                      <Grid container justifyContent={'space-around'} direction='row' alignItems={'center'}   >
                        <Grid item >
                         <EmojiEventsIcon color='primary' style={{ transform:'scale(1.5)'}} />
                        </Grid>
                        <Grid item>
                         <Typography id="modal-reward-title" variant="h6" component="h2" color='GrayText' fontSize={'0.8rem'} >50+</Typography>
                        </Grid>
                      </Grid>
                        
                    </Grid>
                    <Grid item  xs={3} >
                       
                    </Grid>
                    
                </Grid>
          </Grid>
          <Grid item xs={12} marginTop={1}>
          <Typography align={'center'}  color='GrayText' variant="body2" fontSize={{xs:'0.8rem',sm:'0.8rem',md:'0.9rem',lg:'1rem'}} >{t('account.rewards.comebacknotice',{points:50})}</Typography>
          </Grid>
       </Grid>
        }
        
        </Box>
      </Modal>
    </div>
  );
}