import React,{useState,useEffect} from 'react'
import {AdsTerra, Products} from '../../components'
import QueryParams from '../../QueryParams';
import {CardMedia, Grid,Typography} from '@mui/material'
import {axiosClient,config} from '../../services/axiosClient'
import store_icon from '../../assets/icons/online_store_96px.png';
import { myCld } from '../../cloudinaryImage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams,useHistory } from 'react-router-dom';
import useStyles from './styles'
import { truncateString } from '../../utils/Utils';
import { ContentCopy } from '@mui/icons-material';
//import {BasicRating } from '../../components'
const Storefront = ({setShowProgress,onAddToCart,onUpdateLikes,favorites,loadMore,LoadingMore,setLoadingMore,noMoreProducts}) => {
   /*  const imgonLoadError=(e)=>{
        e.target.onerror = null; e.target.src = store_icon
    } */
     const query =QueryParams()
     const history =useHistory()
     const classes = useStyles()

     const[storeid,setStoreId]=useState(query.get('storeId'));
     const [isProductsLoaded,setIsProductsLoaded]=useState(false)
     const [isStoreLoaded,setIsStoreLoaded]=useState(false)
     const [products,setProducts]=useState([])
     const [store,setStore]=useState()
     const [setStoreImage]=useState('')
     const [categories,setCategories]=useState([])
     const {tag}  = useParams()
     const[storeUrl,setStoreUrl]=useState('')
     const[iscopied,setIscopied]=useState(false)
       console.log(tag)
      const ShareStoreUrl = ({storeUrl}) =>{
        return (
           <Grid item>
          { storeUrl !=='' ? <Grid container justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                       
                       <Grid item xs={1} >
               {iscopied ? <ContentCopy color='primary' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${storeUrl}`);setIscopied(true)}}/>: <ContentCopy color='textGrey' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${storeUrl}`);setIscopied(true)}}/>}
               
              </Grid> 
             {/*  <Grid item xs={11}>
          <Typography fontSize='0.7rem' overflow={true}>{`${storeUrl}`}</Typography>
          </Grid> */}
              </Grid>:''}
          </Grid>
          
        )
      }
     useEffect(()=>{

          const fetchProducts = async ()=>{
            try{
                await axiosClient.get(`${process.env.REACT_APP_SERVER_URL}/products/store/${storeid}`,config).then((res)=>{
                setProducts(res.data.products)
               })

            }catch(error){
        
            }
        }

        const getStore = async ()=>{
             fetchStore().then((response) => {
            try{  
              console.log(response.data);
               if (response.status===200){
                    setStore(response.data.store[0])
                    setStoreId(response.data.store[0]._id)
                   setCategories(response.data.store[0].categories)
                   setStoreUrl(`https://www.daabiapi.com/s/${response.data.store[0].tag}`) 
                   
                   console.log(response.data.store[0].tag)

                 //  console.log(response.data.store[0])
                 
                   setStoreImage(store[0].image.secure_url)
               }
                 }catch(err){
                   console.log(err)
                 }
               
               //addToast(exampleToast(response.data.message));
             })
           
         }
           
           
             
  
             const fetchStore = async ()=>{
           
              var url=''
                if (storeid===null){ // id is null tag exist search store by tag else use storeId at storefront route
                  url = `${process.env.REACT_APP_SERVER_URL}/stores/tag/${tag}`;

                }else{
                  url = `${process.env.REACT_APP_SERVER_URL}/stores/${storeid}`; 
                }
              
              return await axiosClient.get(url,config)
            
            };
        
        if (!isProductsLoaded){
           if(storeid!==null){ fetchProducts()}
        }

        if (!isStoreLoaded){
            getStore()
        }



    return ()=>{
           
            if (storeid!==null){
               setIsProductsLoaded(true)
               setIsStoreLoaded(true)
            }
    }
     },[isProductsLoaded, isStoreLoaded, storeid, setStoreImage, store, query, tag, storeUrl])
    

  return (
    <div className={classes.root}>
           <Grid item xs={12} padding={1}>
            <Grid container justifyContent='flex-start'    border={0}  >
             <Grid item xs={2}  onClick={()=>{history.goBack()}}>
                       <ArrowBackIcon htmlColor='darkgray'/>
                    </Grid>
                    <Grid item xs={8}>
                     <Grid container justifyContent={'center'}>
                        <Grid item >
                        <Typography variant="body2" textTransform={'uppercase'} fontWeight={600}color='GrayText'>Store Detail  </Typography>
                        </Grid>
                     </Grid>
                    </Grid>
              </Grid>
            </Grid>{/* :{store ?''} */}
        { 
        store ? <Grid container justifyContent='space-between' marginBottom={2}  padding={2}>
           
          <Grid item xs={12}>
              <Grid container justifyContent={'flex-start'} spacing={1}>
                <Grid item xs={3} sm={3} md={2} lg={10} padding={0}  border={0}  >
                {  store.image ? <CardMedia className={classes.storeimg} image={myCld.image(`${store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()}/> : <CardMedia className={classes.storeimg} image={store_icon}/>}
                </Grid>
                <Grid item xs={9} sm={9} md={10} lg={10} >
                    <Grid container direction='column' paddingTop={1}>
                      <Grid item xs={12}>
                        <Grid container justifyContent={'flex-start'}>
                          <Grid item xs={11}>
                          <Typography variant='h6' fontSize={'1.0rem'}  fontWeight={700} textTransform={'uppercase'} >{truncateString(store.name,20)}</Typography>
                          </Grid>
                          <Grid item xs={1}>
                              <ShareStoreUrl storeUrl={storeUrl}/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item  xs={12}>
                       <Grid container justifyContent={'flex-start'}>
                          <Grid item> 
                          <Typography variant='span'   >{store.country.name}</Typography>
                          </Grid>
                       </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
              </Grid>
          </Grid>

          <Grid item xs={12} marginTop={2} marginBottom={2}>
          <Grid container justifyContent='space-between' direction='column'>
            <Grid item >
                <Grid container justifyContent='flex-start' spacing={0.2} alignItems='center'>
                    <Grid item >
                    <Typography variant='body2' color='GrayText' sx={{fontSize:'0.8rem'}} >{store.description}</Typography>
                    </Grid>
                   
                </Grid>
            </Grid>

           
            </Grid>
            </Grid>
             

             <Grid item  xs={12}>
             <Grid container justifyContent='flex-start' direction={'column'}>
             <Grid item >
                 <Typography variant='body1' align='left' color='black' >Category : </Typography>
                 
              </Grid>

              <Grid item>
              <Grid container>
                  {
                    categories.length> 0 ? <>
                    {
                      categories.map((category,index)=>{
                       return ( <Typography key={category._id}  color='GrayText' variant='body2' sx={{fontSize:'0.8rem'}}>{`${category.name},`}</Typography>)
                      })
                    }
                    </>:''
                  }
             </Grid>
              </Grid>
             
            </Grid>
            
             </Grid>



                <Grid item xs={12} marginTop={2}>
                 <Grid container justifyContent={'space-between'} direction='column'>
                 <Grid item xs={12}>
                 <Typography variant='body1' align='left'color='black' >Products</Typography>
                 <Grid item xs={12} marginTop={2} sx={{padding:0.5,paddingBottom:4}}>
                      {products.length > 0 ? <Products products={products} onAddToCart={onAddToCart} onUpdateLikes={onUpdateLikes} favorites={favorites} loadMore={()=>{}} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts} page="store"/>:''}
                      </Grid>
              </Grid>
               
                      
                
                 </Grid>
                
             </Grid>
             <Grid item sx={{ position: 'fixed', bottom: 48, left: 0, right: 0}}> 
               <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item  alignSelf={'center'} border={0} width={'90vw'}>
                <AdsTerra adkey={'52f66475ed3ada3af8c5929ec857afd5'} height={50} width={320}/>

                </Grid>
               </Grid>
             {/* showAds ? : '' */}
             </Grid>

             {/* <Grid item >
              <Grid container>
             <Grid item> <Typography variant='h6'>Categories :</Typography></Grid>
                           <Grid item>
                           <Grid container>
                           {
                            store.categories.map((category,index)=>{return <Grid item ><Typography variant='body1' >{`${category.name},`}</ Typography></Grid>})
                            }
                           </Grid>
                           </Grid>
              </Grid>

              </Grid> */}
        </Grid>
        
        :''
        }
      
    </div>
  )
}

export default Storefront
