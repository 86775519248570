/* eslint-disable no-unused-vars */
import React from 'react';
import './style.css';
import { TextField,Button,Grid,Card,InputAdornment,Typography} from '@mui/material';
import {useState , useEffect} from "react";
import Alert from './alert/Alert'
import WidrawHistory from './WidraHistory'
import Verifyalert from './alert/verifyalert/Verifylert';
import {StoreCard} from  '../..'
import {axiosClient,axiosPiWidrawClient,config} from '../../../services/axiosClient'
import Storecad from '../../storecard/Storedcad';

const Withdraw = ({store,handlegetTransactions,transactions,balance,handlegetStores,showprogress,setShowProgress,theme ,setMessage,setOpenSnackBar,user,signIn,setBalance,handleSendConfirmationCode,setEmailVerified,handleConfirmedUser, page,perPage,t}) => {
  const [stores]=useState(JSON.parse(localStorage.getItem('stores')));
  const [open,setOpen]=useState(false);
  const [openconfirmation,setOpenconfirmation]=useState(false);
  const [textvalue,setTextValue]=useState(0)
  const [completedPayment,setCompletedPayment]=useState()
  const [account,setAccount]= useState()
  const [isStoreTransLoaded,setIsStoreTransLoaded]=useState(false)
  const [showBtn,setShowBtn]=React.useState(true)
  const [charges,setCharges]=React.useState(0.01)
  const [withdrawalHistory,setWithdrawalHistory]=React.useState()
  const [minimumWithdrawal,setMinimumWithdral]=useState(parseFloat(charges*2))


  
  const handleWithdraw = (amount,ourpay,debitAmount,productid,ordernumber,memo)=>{
    /*     var scopes =["username","payments","wallet_address"]
  signIn(scopes)
 } */
     if (user){ 
           if(amount > -0){
          setShowProgress(true)
          const url ='/payments/a2u/withdraw'    
          axiosPiWidrawClient.post(url,{amount:amount,ourpay:ourpay,debitAmount:debitAmount,uid:user.uid,memo:memo,storeId:store._id},config).then((response) =>{
                 setShowProgress(false)
                 console.log(response.data.status)
                if (response.data.status===500){
                  setMessage({body:t("alerts.need_allow_consent"),severity:'info'})
                  setOpenSnackBar(true)  
                   setShowBtn(true)
                  setOpen(false)
                  setShowProgress(true)
                  var scopes = ["username","payments","wallet_address"]
                  signIn(scopes)
                }else if (response.data.status===501){
                  setMessage({body:t("alerts.too_many_transactions"),severity:'info'})
                  setOpenSnackBar(true)  
                   setShowBtn(true)
                  setOpen(false)
                  setShowProgress(false)
                 
                }else if (response.data.status===502){
                  setMessage({body:t("alerts.too_many_cancel"),severity:'info'})
                  setOpenSnackBar(true)  
                   setShowBtn(true)
                  setOpen(false)
                  setShowProgress(false)
                 
                }else{
                  
                if (response.data.status===200){
                     console.log(response)
                     setCompletedPayment(response.data.completedPayment)
                     setAccount(response.data.account)
                     console.log(parseFloat(response.data.account.balance).toFixed(8))
                     setWithdrawalHistory(withdrawalHistory)
                     setBalance(parseFloat(response.data.account.balance).toFixed(8))
                  setTextValue(0)
                  setMessage({body:t("alerts.withdrawcompleted"),severity:'success'})
                  setOpenSnackBar(true)   
                }else{
                  setMessage({body:t("alerts.operation_occured"),severity:'info'})
                  setOpenSnackBar(true)   
                }
                }
            })
         }else{
          setMessage({body:t("alerts.invalidamount"),severity:'info'})
                 setOpenSnackBar(true)  
         }
      
        
     }
       
   }
  

   const withdraw =()=>{
    const estimate = parseFloat(textvalue) - minimumWithdrawal

          console.log(estimate < 0)
          console.log(estimate)
    console.log(JSON.parse(localStorage.getItem('confirmed')))
    if(JSON.parse(localStorage.getItem('confirmed'))!==true){
      
      setOpenconfirmation(true)

      }else{
        
      if (estimate < 0){
        setMessage({body:t("alerts.minimum",{minimumWithdrawal:minimumWithdrawal}),severity:'info'})
        setOpenSnackBar(true)  
      }
       if (parseFloat(textvalue) <=parseFloat(balance-charges).toFixed(8) ){ 
       
       handleOpen()
      }else{
      setMessage({body:t("alerts.notenoughbalance",{minimumWithdrawal:minimumWithdrawal}),severity:'info'})
      setOpenSnackBar(true)  
      }
     }
       
   
    //console.log(parseFloat(balance-0.001).toFixed(8))
   
    /*
   if (textvalue > 0.001){
    const amount=textvalue-0.001;
    console.log(amount)
    const memo=`widraw  for ${store.name}`
    handleWithdraw(amount,null,null,memo)
   }else{

   } */
  
  }
  
    const onValueChange =(e)=>{
        console.log(e.target.value)
     setTextValue(e.target.value)
    }
    
    const setMax =()=>{
          const value= parseFloat(balance-charges).toFixed(8)
        if (value > 0.0100000){
          setTextValue(value)
          }
        
       }
       

    const handleClose = () => {
        
        setOpen(false);
        setOpenconfirmation(false)
        setCompletedPayment(undefined)
      if (!showprogress){   }
    };
 
  
 
   
 
  useEffect(() => {  

    const getWidrawHistory = async ()=>{

        try{
          var url = `${process.env.REACT_APP_SERVER_URL}/payments/a2u/withdraw/history/${user.uid}`
        
         return await axiosClient.get(url,{},config)
        
        }catch(err){
          console.log(err)
        }
        };
   const handlegetWidraHistory = ()=>{
          getWidrawHistory().then((response) => {
            // console.log(response.data);
       
             if (response.status===200){
                 setWithdrawalHistory(response.data.withdrawalHistory)
             }
       
           })
          }
 if (stores.length >0){
    if (!isStoreTransLoaded){
      handlegetTransactions(page,perPage); 
      handlegetWidraHistory()
      setShowProgress(true) 
    }
  }
  return () =>{
       

    setIsStoreTransLoaded(true)
  }
  },[handlegetStores, handlegetTransactions, isStoreTransLoaded, page, perPage, setIsStoreTransLoaded, setShowProgress, stores, user.uid]);
  /* const getDateNow =(dateNumber)=>{
    var dateString = new Date(parseInt(dateNumber)*1000);
      var newDate= `${dateString.getFullYear()}-${dateString.getMonth()}-${dateString.getDate()} ${dateString.getHours()}:${dateString.getMinutes()}`
      return newDate
   } */ 
   const handleOpen = () => {
    
      if (textvalue > 0){
        setOpen(true)
       }else{
        setMessage({body:t("alerts.amountnotvalid"),severity:'error'})
        setOpenSnackBar(true)  
     }

     
    }
   

  return (
    <div style={{border:'0px solid',height:'100vh'}} >
    
      <Alert open={open} handleOpen={handleOpen} handleClose={handleClose} title={t("dialog.updateonetransaction.title")} textvalue={textvalue} handleWithdraw={handleWithdraw} storename={store.name} completedPayment={completedPayment} account={account} showprogress={showprogress} showBtn={showBtn}setShowBtn={setShowBtn} charges={charges} t={t}/>   

     {
      user ?    <Verifyalert open={openconfirmation} user={user} handleClose={handleClose} title={t("alerts.verification_needed")}textvalue={textvalue} storename={store.name}  account={account} showprogress={showprogress} setShowProgress={setShowProgress} showBtn={showBtn}setShowBtn={setShowBtn} handleSendConfirmationCode={handleSendConfirmationCode} handleConfirmedUser={handleConfirmedUser} setEmailVerified={setEmailVerified} t={t}/> :''
     }

{/*      <StoreCard store={store} balance={balance} theme={theme} t={t}/>
 */}     <Storecad store={store} balance={balance} t={t} theme={theme}/>

       <div className="pageTitleContainer">
           <h1 className="pageTitle">{t("withdraw.title")}</h1>    
           
          </div>
      

         <Grid container padding={1}>
          <Grid item>
           <Card sx={{padding:'10px 2px',boxShadow:0}}>
             <Grid container justifyContent={'space-between'} spacing={2} padding={1}>
               <Grid item xs={12}>
               <Grid container justifyContent={'space-between'} paddingLeft={2} paddingRight={2}>
               <Grid item xs={9}>
                   <TextField 
                   label={t("withdraw.amount_to_withdraw")}
                   InputProps={{style: { padding: 5},startAdornment: <InputAdornment position="start">{`π`}</InputAdornment>
    }} value={textvalue} variant='standard' fullWidth onChange={(e)=>{onValueChange(e)}}/>
    
               </Grid>
               <Grid item xs={2}>
                   <Button className='client-withdraw-max-Button'  variant='outlined'color='primary' onClick={()=>{setMax()}}>{t("withdraw.maximum")}</Button>
               </Grid>
             </Grid>
               </Grid>
               <Grid item xs={12}>
               <Grid container alignItems='center' justifyContent={'center'}  >
                    <Grid item xs={4}>
                   
                    </Grid>
                    <Grid item xs={4}>
                    <Button className='client-withdraw-Button' variant='outlined'  color='primary' onClick={()=>{withdraw()}}>{t("withdraw.withdrawbutton")}</Button>
                    </Grid>
                    <Grid item xs={4}>
                    
                    </Grid>
               </Grid>
               </Grid>
             </Grid>
           </Card>
          </Grid>

          <Grid item   marginTop={3}>
            <Grid container direction='column' alignItems='flex-start'>
            <Grid item marginLeft={2}>
                <Typography variant='h6' fontSize={'0.7rem'} color='GrayText'>{t("withdraw.history")}</Typography>
            </Grid>
              <Grid item>
                <Card sx={{padding:'10px 2px',boxShadow:0}}>
                <Grid container sx={{overFlow:'scroll'}} >
                    <Grid item >
                    <WidrawHistory widrawhistory={withdrawalHistory} t={t}/>
                    </Grid>
                </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
         </Grid>
         
      </div> 
   
  )
}

export default Withdraw

