import React from "react";

const styles = {
  nav: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "100%",
    marginBottom:0,borderTopLeftRadius:30,borderTopRightRadius:30,height:50,
    background: "#f0f0f0",
    padding: "2px 0",
    boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.1)",

  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: 0.5, // Faded loading effect
  },
  icon: {
    width: "24px",
    height: "24px",
    background: "#ddd",
    borderRadius: "50%",
    marginBottom: "6px",
  },
  text: {
    width: "40px",
    height: "5px",
    background: "#ccc",
    borderRadius: "2px",
  },
  middleIcon: {
    width: "38px", // Larger middle icon
    height: "38px",
  },
  "@media (max-width: 768px)": {
    nav: {
      padding: "10px 0",
    },
    icon: {
      width: "20px",
      height: "20px",
    },
    middleIcon: {
      width: "38px",
      height: "38px",
    },
  },
};

const BottomNavFallback = () => {
  return (
    <div style={styles.nav}>
      {[1, 2, 3, 4, 5].map((_, index) => (
        <div key={index} style={styles.iconContainer}>
          <div style={index === 2 ? { ...styles.icon, ...styles.middleIcon } : styles.icon}></div>
          {index === 2 ? '' :<div style={styles.text}></div>}
        </div>
      ))}
    </div>
  );
};

export default BottomNavFallback;
