import {Box,Grid, Typography } from '@mui/material'
import React from 'react'
import maintenance_mode from '../../assets/icons/maintenance_480px.png'
import { ThemeProvider, createTheme} from '@mui/material/styles';
import { blue,red} from '@mui/material/colors';
import { Medialinks } from '..';
import {BrowserRouter as Switch,Route,useHistory,useParams
} from "react-router-dom";
  const theme = createTheme({
    palette: {
      primary:{
     /*    main:"#faa532", */
        main:"#6d1b7b",
      /*   main:"#ff9800", */
      },
        secondary: {
            main:red[500],
            contrastText:'#fff'
        },
        warning:{
          main:red[100],
          contrastText:'#fff'
        },
        link:{
          main:blue[100],
          contrastText:'#fff'
        },
        info:{
          main:"#6d1b7b",
         /*  main:"#c75bda", */
         contrastText:'#fff'
        },
        Paid:{
          main:"#2a7ade",
          contrastText:'#fff'
        },
        Shipped:{
          main:"#d39012",
          contrastText:'#fff'
        },
        Recieved:{
          main:"#8fd312",
          contrastText:'#fff'
        },
        Completed:{
          main:"#34af4d",
          contrastText:'#fff'
        },
        textGrey:{
          main:"darkgrey",
          contrastText:'#fff'
        }
      
      },
}); 
const MaintainanceMode = ({ref}) => {
    const [mediaLinks] =React.useState({
        "socialmedialinks": [
            {
                "_id": "63bed479a757ee4628826a32",
                "linktype": "social",
                "medialinks": [
                    {
                        "title": "Facebook",
                        "linktext": "https://www.facebook.com/daabiamalll"
                    },
                    {
                        "title": "Instagram",
                        "linktext": "https://www.instagram.com/"
                    },
                    {
                        "title": "Twitter",
                        "linktext": "www.twiter.com/#"
                    },
                    {
                        "title": "Telegram",
                        "linktext": "https://t.me/+JSpDUNB416QyMDNk "
                    },
                    {
                        "title": "WhatsApp",
                        "linktext": "https://chat.whatsapp.com/FofqI8yt7D37E9wKmPJuz4"
                    }
                ],
                "__v": 0
            },
            {
                "_id": "63bed479a757ee4628826a36",
                "linktype": "social",
                "medialinks": [
                    {
                        "title": "Facebook",
                        "linktext": "w"
                    },
                    {
                        "title": "Instagram",
                        "linktext": ""
                    },
                    {
                        "title": "Twitter",
                        "linktext": ""
                    }
                ],
                "__v": 0
            },
            {
                "_id": "63bed479a757ee4628826a38",
                "linktype": "social",
                "medialinks": [
                    {
                        "title": "Facebook",
                        "linktext": "ww"
                    }, 
                    {
                        "title": "Instagram",
                        "linktext": ""
                    },
                    {
                        "title": "Twitter",
                        "linktext": ""
                    }
                ],
                "__v": 0
            }
        ],
        "status": 200
    })
    const [medialinksObj,setMedialinksObj]=React.useState()

    React.useEffect(()=>{
        console.log(mediaLinks)
        const Obj = {
          facebook:mediaLinks.socialmedialinks[0].medialinks[0].linktext,
          twitter:mediaLinks.socialmedialinks[0].medialinks[1].linktext,
          instagram:mediaLinks.socialmedialinks[0].medialinks[2].linktext,
          telegram:mediaLinks.socialmedialinks[0].medialinks[3].linktext,
          whatsapp:mediaLinks.socialmedialinks[0].medialinks[4].linktext,

        }
        setMedialinksObj(Obj)

  },[mediaLinks])
    
  return (
    <div >

<Route exact path="/maintenancemode"> 


    <Box sx={{ pb: 0 ,border:0,height:'auto',backgroundColor:'#f3e6f9'}} ref={ref}  style={{height:'100vh',backgroundColor:'whitesmoke'}} >
      <Grid container justifyContent={'center'} direction={'column'} alignItems={'center'} paddingTop={'25vh'}>
            <Grid item >
                 <Grid container justifyContent={'center'} alignItems={'center'} direction='column' spacing={2}>
                    <Grid item>
                    <img height={100} src={maintenance_mode} alt='maintenance mode' />
                    </Grid>
                    <Grid item alignContent={'center'}>
                        <Typography variant='h5' color='darkgray'>
                            MAINTENANCE MODE
                        </Typography>
                    </Grid>
                 </Grid>
            </Grid>

           { medialinksObj ?<Grid item >
                <Grid container justifyContent={'center'} alignItems={'center'} padding={2} marginTop={2} spacing={1}>
                    <Grid item >
                     <Typography variant='body2' align='center' color='darkgray'>In the mean time follow our social media channels to stay updated when we are back</Typography>
                    </Grid>
                    <Grid item>
                        <Medialinks medialinks={medialinksObj}/>
                    </Grid>
                </Grid>
            </Grid>:''}
            <Grid item >
                  
           </Grid>
      </Grid>
    </Box>
    </Route>
    </div>
  )
}

export default MaintainanceMode