import React from "react";

const ProductsGridFallback = () => {
  return (
    <div style={styles.grid}>
      {Array(6).fill(0).map((_, index) => (
        <div key={index} style={styles.card}>
          <div style={styles.image}></div>
          <div style={styles.textLong}></div>
          <div style={styles.textShort}></div>
          <div style={styles.textLong}></div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))", // Auto-fit for responsiveness
    gap: "4px",
    padding: "8px", // Increased padding for better spacing
    animation: "pulse 1.5s infinite ease-in-out",
  },
  card: {
    background: "#f0f0f0",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  image: {
    height: "150px",
    background: "#ddd",
    borderRadius: "6px",
  },
  textShort: {
    height: "12px",
    background: "#ccc",
    width: "75%",
    marginTop: "10px",
    borderRadius: "4px",
  },
  textLong: {
    height: "12px",
    background: "#ccc",
    width: "70%",
    marginTop: "8px",
    borderRadius: "4px",
  },
  /** Responsive styles using @media queries in JS */
  "@media (max-width: 768px)": {
    grid: {
      gridTemplateColumns: "1fr", // Single column on small screens
      padding: "6px",
    },
    image: {
      height: "120px", // Smaller images on mobile
    },
  },
  "@media (min-width: 1024px)": {
    grid: {
      gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))", // Wider cards on large screens
    },
    image: {
      height: "200px", // Larger images on bigger screens
    },
  },
};


export default ProductsGridFallback;
