import React from "react";  
import { Typography } from "@mui/material";  

// Custom Typography component  
const CustomTypography = ({ children, fontSize, color, ...props }) => {  
  return (  
    <Typography  
      {...props}  
      sx={{  
        fontSize: fontSize || {  
          xs: "0.8rem", // Default for extra-small screens  
          sm: "0.8rem", // Default for small screens  
          md: "0.8rem", // Default for medium screens  
          lg: "1rem",  // Default for large screens  
        },  
        color: color || "textGrey", // Default text color  
        ...props.sx, // Allow custom sx overrides  
      }}  
    >  
      {children}  
    </Typography>  
  );  
};  

export default CustomTypography;  
