import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    padding:2,
    marginBottom:4,
    height:'auto',
    boxShadow:1,
    border:'1px solid #a9a9a933',
    [theme.breakpoints.down('xs')]:{
      boxShadow:0,
      margin:1,
      elevation:0,
      border:'1px solid #a9a9a933',
      height:'auto'
    },
  },
  media: {
    margin:'5px 5px',
    height: 100,
    border:'1px solid var(--app-secondary)',
    borderRadius:'4px',
    width:'90%',
    padding:0,
  },
  productname:{
   fontWeight:600,
   fontSize:'1.2rem',
   [theme.breakpoints.down('xs')]: {
    fontWeight:500,
  
     margin: '5px',
     fontSize:'0.9rem'
  },
  },
  cardContent: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    border:'0px solid',
    width:'100%',
    padding:0.5,
    margin:0,
  },
  cardActions: {
    border:'1px solid',
    height:'auto',
    margin:'0px',
    padding:'0px',
    minHeight:'0px',
    display:'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
    
  },
 
  buttons: {
    width:'100%',
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
  },
  quantityUpdateWrapper:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
  },
  selectCheckBox:{
      color:"primary",
        '&.Mui-checked': {
           color: "primary",
        },
      
  },
  price:{
     color:'red',
     fontWeight:600,
     fontSize: '28px',
     [theme.breakpoints.down('xs')]: {
      fontWeight:500,
      fontSize: '18px',
       margin: '5px',

    },
  },
  quantity:{
    width:'100%',textAlign:'center',
    border:' 1px #afb4b0',
    color:'var(--app-primary)',  
    /* backgroundColor:'var(--app-primary)',*/  
     fontSize:'1.3rem',
    [theme.breakpoints.down('xs')]: {
      width:'100%',
      textAlign:'center',
      border:' 1px var(--app-primary)',
      fontSize:'1rem'
    },
   
  },
  cost:{
      height:'auto',    
      color:'#afb4b0',
      border:'1px solid',
     
  },
  costTypo:{
    fontSize:'18px',
    color:'var(--app-secondary)',
    fontWeight:600,
    [theme.breakpoints.down('xs')]: {
      fontSize:'13px'

   },
  },
  specifications:{
    margin:'0px',
    padding:'0px',
   height:'auto',
   display:'flex',
   flexDirection:'column',
   border:'0px solid',
   width:'100%',
/*    marginBottom:'5px' */
  },
  specsListWrapper:{
    padding:'0px',
    border:'0px solid',
    margin:0,

  },
  gridSpecsList:{
    border:'0px solid #afb4b0',
    width:'100%',
    margin:0,
  },
  specsGrid:{
    border:'0px solid #afb4b0',
    width:'100%',
    padding:'5px 0px',
    display:'flex',
    justifyContent:'space-around',
  },
  gridSpecsItem:{
    cursor:'pointer',
    fontSize:12,
    border:'1px solid var(--app-primary) !important'
  },
  disSelect:{
    padding:'2px 4px',
    border:'1px solid #afb4b0',
    borderRadius:'30px',
    fontSize:'10px'
  }
 ,select:{
    padding:'2px 4px',
    border:'1px solid var(--app-primary)',
    borderRadius:'30px',
    backgroundColor:'var(--app-primary)',
    color:'white',
    fontSize:'10px'

  }
  ,measurementCard:{
    height:'auto',
  },
  measurementFormWrapper:{
    border:'0px solid  #afb4b0',
    padding:'5px',
    marginBottom:'5px'

  },
  measurementForm:{
    border:'1px solid',
    borderRadius:'3px',
    padding:'10px 10px',
  },
  measurementInputWrapper:{
    display:'flex',
    flexDirection:'column'
  },
  measurementInput:{
    width:'100%',height:'25%' ,textAlign:'center'
  }
 ,
  measuregridContainer:{
    border:'1px solid  #afb4b0',
    borderRadius:'4px',
    padding:'10px 10px',
    margin:'0px 0px 5px 0px'
    
  }
}));
