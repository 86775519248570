import React from 'react'
import './style.css';
import {CardMedia, Grid,Typography,Card} from '@mui/material'
import storeThumbnail from '../../assets/icons/online_store_96px.png';
//import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useHistory} from 'react-router-dom'
import useStyles from './style'
import { myCld } from '../../cloudinaryImage';
import { truncateStringNoSuffix } from '../../utils/Utils';
import CustomTypography from '../customComponent/CustomTypography'
const Storecad = ({store,balance,sales,theme,t}) => {
    const classes =  useStyles(theme)
    const [setStoreImage]=React.useState(storeThumbnail)
    const history =useHistory()
    React.useEffect(()=>{
   try{
    const imgurl =myCld.image(`${store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()
    store.image.public_id!==undefined?setStoreImage(imgurl):setStoreImage(storeThumbnail)
   }catch(err){
    console.log(err)
   }
    },[setStoreImage, store.image])
  return (


    
  <Grid container  justifyContent={'space-between'} direction='column'  sx={{backgroundColor:'rgba(0, 0, 0, 0.041)',paddingBottom:{sm:1,md:2,lg:5}}}>
  
    <Grid item  xs={12}>
        <Grid container sx={{backgroundColor:'var(--app-secondary)',borderBottomLeftRadius:30,height:{xs:120,lg:140}}} direction={'column'} padding={1}>

          <Grid item sx={12}  >
                <Grid container justifyContent={'flex-start'} spacing={0.2} alignItems={'baseline'}>
               {/*  <Grid item sx={1}  onClick={()=>{history.goBack()}}>
            <ArrowBackIcon  htmlColor='primary' color='textWhite'/>
          </Grid> */}
            <Grid item xs={6}>
             <Grid container justifyContent='flex-start' alignItems='baseline' >
             <Grid item xs={2} lg={1} marginBottom={0} onClick={()=>{  history.push('/my-store')
}}>
                
                {  store.image ? <CardMedia  className={classes.storeImg}  image={myCld.image(`${store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()}/> : <CardMedia className={classes.storeimg} image={storeThumbnail}/>}

            </Grid> 
            
            <Grid xs={10} item  ><Typography onClick={()=>{  history.push('/my-store')
}} sx={{color:'var(--app-primary)'}} variant='h5' fontSize={'1rem'} fontWeight={500} color={'white'}>{truncateStringNoSuffix(store.name,18)}</Typography></Grid>

             </Grid>
            </Grid>
            <Grid item xs={5} >
                <Grid container justifyContent={'flex-end'}>
                    <Grid item ><Typography variant='h6' fontSize={'0.7rem'} sx={{color:'var(--app-primary)'}}>{store.merchantId}</Typography></Grid>
                </Grid>
            </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    </Grid>
      <Grid item xs={12} sx={{/* backgroundColor:'var(--app-secondary)', */borderBottomLeftRadius:30,marginTop:{xs:-6,lg:-6}}}/*  marginTop={-7} */  paddingLeft={1.5} paddingRight={1}>
          <Card elevation={0} sx={{ height:{xs:80,lg:100}, background: 'white', opacity: 0.80,padding:2,borderRadius:2}}>
          <Grid container direction='column' justifyContent='space-between' > 
          <Grid item>
         {sales ? <Grid container justifyContent='spacebetween'>
            <Grid item>
            <CustomTypography  variant='h6'sx={{color:'var(--app-primary)',fontSize:{    
          md: "0.8rem", // Default for medium screens  
          lg: "1.5rem",  // Default for large screens  
          } }}>{sales.count}</CustomTypography>
            </Grid>
            <Grid item>

            </Grid>
          </Grid>:""}
            </Grid>
          <Grid item >
           <Grid container justifyContent={'flex-start'} color='primary' spacing={1}>
            <Grid item>
            <CustomTypography variant='h5' sx={{color:'var(--app-primary)',fontSize:{lg:'1.5rem'}}} fontWeight={500} >{balance!==undefined ?balance:'0.0000000'}</CustomTypography>
            </Grid>
            <Grid item ><CustomTypography  variant='h5' sx={{color:'var(--app-primary)',fontSize:{lg:'1.2rem'}}} fontWeight={500} >π</CustomTypography></Grid>

           </Grid>
          </Grid>

               
               
            </Grid>
          </Card>
      </Grid>
    </Grid>
  )
}

export default Storecad
