import React from "react";  
import { TextField } from "@mui/material";  

// Custom TextField component with reusable styles  
const CustomTextField = (props) => {  
  return (  
    <TextField
      {...props}  
      InputProps={{
        style: { padding: "4px 8px" }, // Reusable padding styles  
        ...props.InputProps, // Allow overriding or adding more InputProps  
      }}  
      sx={{  
        "& .MuiOutlinedInput-root": {  
          padding: "4px", // Outer padding adjustment  
          backgroundColor:'#fff',

        },  
        "& .MuiInputBase-input": {  
          padding:"4px", // Inner padding adjustment  
          fontSize:{sm:"12px",lg:"14px"}, // Adjust font size  

        },  
        ...props.sx, // Allow additional custom styles via sx prop  
      }}  
    />  
  );  
};  

export default CustomTextField;  
