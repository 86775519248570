import React from 'react'
import {Grid,Button} from '@mui/material'

const ProductLife = ({product,variant}) => {
  return (
    <div>
      {
    product.lifespan !=='' ?   <Grid container  /* height={height} */justifyContent='flex-start' border={0} spacing={0.5} >
            <Grid item marginTop={1}>

            { product.lifespan ==='Used Product'?   <Button sx={{textTransform:'none',fontSize:'0.6rem',padding:0,paddingLeft:0.5,paddingRight:0.5}}size="small" variant="outlined" color="Shipped" >{product.lifespan}</Button>
             :''
            
            /* <Button sx={{fontSize:'0.7rem'}} variant={`${variant}`}className='client-product-life' color='Shipped'>{product.lifespan}</Button> <Typography fontSize={'0.6rem'} sx={{bgcolor:`${product.life==='New Product'? '#34af4d':'#d39012'}`,padding:0.2}} color='whitesmoke'>{product.lifespan}</Typography>  <img alt='product lifeSpan' src={usedPorudctIcon}  height={25} width={25}/> */
            
            }
            </Grid>
 
            
        </Grid> :'' 
      }
    </div>
  )
}

export default ProductLife
