import { lazy } from 'react';

export {default as Appbar}   from './appbar/Appbar';

//export {default as Topbar}   from './topbar/Topbar';
export const Topbar = lazy(() => import('./topbar/Topbar'));

export {default as TopbarcontacInfo}   from './topbar/contactinfobar/ContactInfowidget';
export const Products = lazy(() => import('./products/Products'));

/* export {default as Search} from './search/Search';
 */export {default as Store} from './store/Store';
export {default as NewStore} from './newStore/NewStore';
export {default as Cart}     from './cart/Cart';
export {default as Orders}   from './orders/Orders';
export {default as ProceedCheckOut} from './proceedcheckout/ProceedcheckOut';
export {default as PrefaredStyleCheckOut} from './prefareStylecheckout/PrefareStyleCheckout';
/* export {default as BottomNav} from './bottomnavigation/BottomNav';*/

export const BottomNav = lazy(() => import('./bottomnavigation/BottomNav'));

export {default as Account} from './account/Account';
export {default as CategoryWidget} from './widgets/categoryWidget/CategoryWidgets';
export {default as Category} from './category/Category';
export {default as Footer} from './footer/Footer';
export {default as InfoFooter} from './footer/InfoFooter';
export {default as Herosection} from './herosection/Herosection';
export {default as AboutUs} from './aboutus/AboutUs';
export {default as Confirm} from './email/Confirm';
//Prodcut manager
export {default as PmNewProduct} from './productManager/newproduct/NewProduct';

export {default as PmProduct} from './productManager/pmproduct/PmProduct';
export {default as PmProductList} from './productManager/cproductList/PmProductList';
export {default as Transactions} from './productManager/transactions/Transactions'
export {default as Withdraw} from './productManager/widraw/Withdraw'
export {default as BasicRating} from './rating/BasicRating'
export {default as MyStore} from './mystore/MyStore'
export {default as Search} from './search/Search'
export {default as Medalalerts} from './modal/medalalerts/Medalalerts'
export {default as Medialinks} from './medialinks/Medialinks'
export {default as Privacy} from './privacy/Privacy'
export {default as ContactUs} from './contactus/ContactUs'
export {default as TermsofService} from './privacy/TermsOfService'
export {default as AdsTerra} from './ads/Adsterra'
export {default as FloatComponent } from './floatingcomponent/FloatComponent'