import React from 'react'
import {Grid,Typography,Button} from "@mui/material"
import {Link} from 'react-router-dom';
import './contentTitle.css'
const ContentTitleBar = ({store,pagetitle,buttons}) => {
   /*  const buttons=[{title:'Add New',link:`/dashboard/newproduct?storeId=${store._id}&storeName=${store.name}`},{title:'Products',link:`/dashboard/products?storeId=${store._id}&storeName=${store.name}`}] */
  return (
    <Grid container className='client-title-ba' padding={0.5}backgroundColor='#fff'>
  {/*   <Grid container justifyContent='space-between' marginTop={1}>
          
       {
        store !==null ?   <Grid item  xs={12} sm={12} md={12} lg={12} >
        <Grid container justifyContent='space-between' >
          <Grid item >
            <Typography variant='body2'>{store.name}</Typography>
          </Grid>
          <Grid item >
          <Typography variant='body2'>{` Local currency: ${store.currency}`}</Typography>
          </Grid>
        </Grid>
      </Grid>:''
       }
  </Grid> */}
  <Grid container justifyContent='space-between' >
   
         <Grid item  xs={4} sm={4} md={6} lg={6}>
           <Grid container justifyContent='space-between'>
             <Grid item >
               <Typography fontSize={'1rem'} variant='h6'>{pagetitle}</Typography>
             </Grid>
           </Grid>
         </Grid>

         <Grid item  xs={8} sm={8} md={6} lg={6}>
           <Grid container justifyContent='flex-end' spacing={1}>
            { 
               buttons.map((button,index)=>{
                return  <Grid item key={`content-title-bar-btn-${index}`}>
                <Link className='link' to={button.link}>
                <Button variant='outlined' className='client-titleButton' >{button.title}</Button>
          </Link>
                </Grid>
               })
            }
            
           </Grid>
         </Grid>

  </Grid>
    </Grid>
  )
}

export default ContentTitleBar
